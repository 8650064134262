import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { common } from 'src/app/common/common';
import { AJWebService } from 'src/services/ajWeb.service';

@Component({
  selector: 'app-heat-map-chart-pop',
  templateUrl: './heat-map-popup-chart.component.html',
  styleUrls: ['./heat-map-popup-chart.component.css']
})

export class HeatMapPopUpChartComponent implements OnInit {

  //@ViewChild('TktDistHeatMapPopUp') TktDistHeatMapPopUp: ElementRef;
  @Input() private data: Array<any>;
  @Input() HeatMapGraphData: any;
  private chart: any;
  private xScale: any;
  private yScale: any;
  private colors: any;
  private xAxis: any;
  private yAxis: any;
  selector = 'app-heat-map-chart-pop-div';
  myData: { variable: string; value: string; type: string; }[];
  myColor: any;
  margin = { top: 30, right: 50, bottom: 220, left: 50 };
  //containerHeight = 470;
  width: number;
  height: number;
  svg: any;
  xAxisText = "Sub Category";
  yAxisText = "Queue Name";

  constructor(public ajWebService: AJWebService) {

  }

  ngOnInit() {
    this.ajWebService.showTicketDistributionChartInPopUp.subscribe(opportunityQueues => {
      this.myData = this.ajWebService.ticketDistributionData;
      this.drawGraph();
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
  }

  drawGraph() {
    this.renderSVGCOntainer();
    this.renderXAXis();
    this.renderYAxis();
    this.renderHeatMap();
    this.renderXAxisText();
    this.renderYAxisText();
    //this.renderHeatMaplegend();
  }

  renderSVGCOntainer() {
    //let elem = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement.parentElement;
    let elem = <HTMLElement>(document.getElementsByClassName(this.selector)[0]);
    elem.innerHTML = "";
    // set the dimensions and margins of the graph     
    //this.width = elem.offsetWidth - this.margin.left - this.margin.right;
    //this.height = elem.offsetHeight - this.margin.top - this.margin.bottom;
    this.width = 400;
    this.height = 250;
    // append the svg object to the body of the page
    //   this.svg = common.getSVG(this.selector, this.width, this.height)
    //  .append("g")
    //  .attr("transform",
    //    "translate(" + this.margin.left + "," + this.margin.top + ")");
    let parentElement = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement;
    this.svg = d3.select(elem)
      .append('svg')
      .attr('width', 700)
      .attr('height', 420) //element.offsetHeight - this.margin.height);
      // .attr('transform', `translate(50,0)`);
      .append("g")
      .attr("transform",
        "translate(" + this.margin.left * 4 + "," + this.margin.top + ")");
  }

  x() {
    var myGroups = this.myData.map(d => d.type);
    return d3.scaleBand()
      .range([0, this.width])
      .domain(myGroups)
      .padding(0.02);
  }

  y() {
    var myVars = this.myData.map(d => d.variable);
    return d3.scaleBand()
      .range([this.height, 0])
      .domain(myVars)
      .padding(0.2);
  }

  renderXAXis() {
    this.svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x()).ticks(10))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", ".1em")
      .attr("dy", ".2em")
      .attr("transform", "rotate(-45)");

    // this.svg.append("g")
    //   .style("font-size", 10)
    //   .attr("transform", "translate(0," + (this.height) + ")")
    //   .call(d3.axisBottom(this.x()).tickSize(0).tickPadding(10))  //comment
    //   .select(".domain") //comment
    //   .selectAll(".tick")
    //   //.selectAll("line").remove();
    //   .call(g => g.select(".domain"))
    //   .selectAll("text")
    //   .style("text-anchor", "end")
    //   .attr("dx", "-.8em")
    //   .attr("dy", ".15em")
    //   .attr("transform", function (d) {
    //     return "rotate(-65)"
    //   });
  }

  renderYAxis() {
    this.svg.append("g")
      .style("font-size", 10)
      //.call(d3.axisLeft(this.y()).tickSize(0).tickPadding(10))
      .call(d3.axisLeft(this.y()).ticks(10));
    //.select(".domain").remove();
  }


  renderXAxisText() {
    this.svg.append("text")
      .attr("class", "xAxisText")
      .attr("transform",
        "translate(" + (this.width / 2) + " ," +
        (this.height + this.margin.bottom / 2 + 20) + ")")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "11px")
      .text(this.xAxisText);
  }

  renderYAxisText() {
    this.svg.append("text")
      .attr("class", "yAxisText")
      .attr("y", 0 - (this.margin.left + this.margin.right - 80))
      .attr("x", 0 - (this.height / 2 - 20))
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .attr("font-family", "sans-serif Medium")
      .attr("font-size", "11px")
      .text(this.yAxisText);
  }
  renderHeatMap() {
    // Build color scale
    var colors: any = ["#fafafa", "#4008F4", "007afc", "3200fc"];
    //var colors:any =[ "#0000ff",'#6600ff','#944dff','#c299ff','#6969fc']
    this.myColor = d3.scaleLinear()
      .domain([1, 6])
      .range(colors);

    // var myColor = d3.scaleLinear()
    // .range(['#ffffff', '#69b3a2'])
    // .domain([1, 100])

    // create a tooltip
    var tooltip = d3.select('#ticketDistHeatMapPop')
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "3px")
      .style("padding", "5px");

    // Three function that change the tooltip when user hover / move / leave a cell
    var mouseover = function (d) {
      tooltip
        .style("opacity", 1)
      d3.select(this)
        .style("opacity", 1)
    };

    var mousemove = function (d) {
      tooltip
        .html(d.variable + "</br>" + d.type + "</br> Tickets:" + d.value)
        .style("left", (d3.mouse(this)[0]) + "px")
        .style("top", (d3.mouse(this)[1]) + "px")
    };
    var mouseleave = function (d) {
      tooltip
        .style("opacity", 0)
      d3.select(this)
        .style("opacity", 0.8)
    };

    // add the squares
    this.svg.selectAll()
      .data(this.myData, d => d.value)
      .enter()
      .append("rect")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("x", d => this.x()(d.type))
      .attr("y", d => this.y()(d.variable))
      .attr("width", this.x().bandwidth() / 1.02)
      .attr("height", this.y().bandwidth() * 0.8 / 1.02)
      .style("fill", (d: any) => this.myColor(d.value))
      .style("stroke-width", 4)
      .style("stroke", "none")
      .style("opacity", 0.8)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .append('svg:title')
      .text(d =>
        d["variable"] + ", Tickets : " + d["value"] + ", " + d["type"]
      )
  }

  renderHeatMaplegend() {
    let parentElement = <HTMLElement>document.getElementsByClassName(this.selector)[0].parentElement.parentElement;
    var tranY = parentElement.offsetHeight - this.margin.bottom;
    var svg1 = this.svg
      .append("g")
      .attr("class", "legendsvg")
      .attr("width", parentElement.offsetWidth)
      .attr("height", this.margin.bottom)
      .attr("transform", "translate(" + -10 + "," + (parentElement.offsetHeight - 110) + ")");

    var legendGroup = svg1.selectAll(".legend")
      .data(this.myData, d => d.Value)
      .enter().append("g")
      .attr("transform", function (d, i) {
        return "translate(" + (i * 20) + "," + 0 + ")";
      });

    var colors: any = ["#fafafa", "#4008F4", "007afc", "3200fc"];
    this.myColor = d3.scaleLinear()
      .domain([1, 6])
      .range(colors);

    legendGroup.append("circle")
      .attr("cx", "20")
      .attr("cy", "480")
      .attr("r", 4)
      .data(this.myData, d => d.Value)
      .attr('fill', (d, i) => colors[i])
      .attr("stroke", (d, i) => { colors[i]; })
      .attr("stroke-width", 2)

    legendGroup.append("text")
      .attr("x", 10)
      .attr("y", 20)
      .text(function (d) { return d.value; });

    // legendGroup.append('text')
    // //.attr("class", function (d) { return "legendText" + d.key.replace(/[^\w]/gi, '') })
    // .attr("id", "textLegend")
    // .style("color", "#9657D5")
    // .style("font-size", "11px")
    // //.style("font-weight", "bold")
    // .style("cursor", "pointer")
    // .style("fill", "#000")
    // .attr("text-anchor", "start")
    // .attr("x", "25")
    // .attr("y", "60")
    // .style('font-family', '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif')
    // .append('tspan')
    // .text(function (d) {
    //   if (this.myData.length > 50) { d =>d.value.toString(); }
    //   return d.toString();
    // })
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   //debugger;
  //   if (event) {
  //     common.clearSVG(this.selector);
  //     this.drawGraph();
  //   }
  // }
}
