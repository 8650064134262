import { Component, OnDestroy, ViewChild, Input } from '@angular/core';


import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AJWebService } from '../services/ajWeb.service';
import { Subject, Subscription } from 'Rxjs';
import * as jwt_decode from "jwt-decode";
import { NgxSpinnerService } from "ngx-spinner";
import { BnNgIdleService } from 'bn-ng-idle';

declare var bootbox: any;

import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/services/app-config.service';
import { HomeComponent } from 'src/components/home/home.component';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, AuthError, EventMessage, EventType } from '@azure/msal-browser';

declare var userNotification: any;

enum UserRoleType {
  AdminSuper = 'AA',
  AdminAccenture = 'AC',
  AdminAvanade = 'AV',
  PortfolioEditor = 'PE',
  PortfolioViewer = 'PV',
  DeliveryLeadEditor = 'DE',
  DeliveryLeadViewer = 'DV',
  ProjectManager = 'PM',
  TeamMember = 'TM',
  ConfigurationManager = 'CM'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {


  private readonly _destroying$ = new Subject<void>();
  private subscription: Subscription;
  private CMSLevelOneUId: any;
  private CMSLevelTwoUId: any;
  private CMSLevelThreeUId: any;
  private CMSLevelFourUId: any;
  private CMSLevelFiveUId: any;
  public IsCMSSelectable: boolean = true;

  public cmsOrWS: string;
  public cmsOrWSUid: any;
  public cmsOrWSId: any;
  public wsUid: any;
  public lastSelectedcmsOrWS: string;
  public lastSelectedcmsOrWSUid: any;
  public lastSelectedcmsOrWSId: any;
  public lastSelectedwsUiD: any;
  public IomCMS: any;
  // public AofCMS: any;
  public IomWS: any;
  // public AofWS = null;
  public E2E: any;

  public clientList = [];
  public cmsList = [];
  public wsList = [];
  public LoadOpportunityRequest = [];
  public FilteredCMSWSRequest = [];

  public scopeSelector = {};
  public clientName: string;
  public ClientId: string;

  public selectedClientId: number;
  public selectedClientName: string;
  public selecteEndtoEndName: string;
  public IsWorkStreamSelectable: boolean = false;

  public cmsName: string;
  public selectedCMSName: string;

  public workstreamName: string;
  public EndtoEndExport: any;

  public endToEndName: string;
  public endToEndType: string;
  public roleTypeCode: string;
  public roleAccessCode: string;
  public workstreamId: any;
  public workstreamUId: any;
  public clientWMSId: any;
  public clientWMSUId: any;
  public endToEndId: any;
  public CMSUId: any;

  public ProjectId: string;
  public isAuthorised: boolean;
  public isActive: boolean;
  public IsScopeApplied: boolean;

  public clientUser: boolean;
  public scopeDisplayTitle: string;
  public scopeSelectortoggle: boolean = false;
  //public roleType: UserRoleType;
  public contractOpportunityInitiativeGroups: any;
  public currentSelectedEndToEnd: any;
  public currentSelectedCMS: any;
  public currentSelectedWorkstream: any;
  public showReviewPortfolio: boolean;
  public showManageProject: boolean;
  public showExecuteTasks: boolean;
  public DeliveryConstructAttributeForCMS: any;

  public showScopeSelector: string = "";
  public clickedonheadervalues: boolean = true;
  public token: any;
  public environmentDetails: any;

  public isIframe: boolean;
  public payload_token: any;
  clientNameLimit: string;
  endToEndNameLimit: string;
  scopeDisplayTitleLimit: string;

  constructor(public ajWebService: AJWebService, private broadcastService: MsalBroadcastService, private authMSALService: MsalService, private SpinnerService: NgxSpinnerService, private route: Router, private activatedRoute: ActivatedRoute, private appConfig: AppConfigService, private bnIdle: BnNgIdleService) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    //this.ajWebService.endToEndUId = window.location.href.substr(window.location.href.lastIndexOf(':') + 1, 10);
    //localStorage.setItem("endToEndUId", this.ajWebService.endToEndUId);
    this.bnIdle.startWatching(600).subscribe((res) => {
      if (res) {
        this.ajWebService.showInfoMessage("Session Expired ");
   localStorage.clear();
   sessionStorage.clear();
   this.logout();
     console.log("Session Expired");
     }
     })
    this.isIframe = window !== window.parent && !window.opener;

    this.ajWebService.CMSUId = ((this.currentSelectedCMS != undefined && this.currentSelectedCMS.CMSUId != undefined) ? this.currentSelectedCMS.CMSUId : "null");
    this.ajWebService.WorkstreamUId = ((this.currentSelectedWorkstream != undefined && this.currentSelectedWorkstream.WorkstreamUId != undefined) ? this.currentSelectedWorkstream.WorkstreamUId : "null");
    //Cofidentiality Popup
    //this.initializeUserNotificationContent();
  }
  isLoggedIn(): boolean {

    return this.authMSALService.instance.getActiveAccount() != null
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnInit(): void {
    //userNotification.showUserNotificationModalPopup();

    this.environmentDetails = this.appConfig.environmentSettings;

    if (this.environmentDetails.MSALenabled == true) {
      this.E2E = window.location.href.split("/").pop();
      sessionStorage.setItem('E2E', this.E2E);
      console.log('************Welcome AJ PAM ******');
      //MSAL 2.0 code 
      if (!this.isLoggedIn()) {
        console.log('************ user not logged in .. enter login details ******');
        this.authMSALService.instance.loginRedirect();
        this.authMSALService.instance.handleRedirectPromise().then(res => {
          if (res != null && res.account != null) {
            this.authMSALService.instance.setActiveAccount(res.account);
            console.log(res);
            const id_token = res.idToken;
            const access_token = res.accessToken;
            //'*****************....Sart....************************'

            //'******************...END....*************************'
            sessionStorage.setItem('token', JSON.stringify(id_token));
            this.payload_token = this.getDecodedAccessToken(id_token); // decodeing tokan here
            sessionStorage.setItem('userId', this.payload_token.preferred_username);
            //console.log('userName---', this.payload_token.preferred_username);
            this.ajWebService.enterpriseId = res.account.username.split('@')[0];
            localStorage.setItem('msal.idtoken', JSON.stringify(id_token));
            if (localStorage.getItem('msal.idtoken') != undefined && localStorage.getItem('msal.idtoken') != '')
              this.ajWebService.showConfidentialityMessage('You are using an Accenture proprietary tool ("myWizard"). You understand that this tool may contain  <strong>ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION</strong>. You are obligated to adhere to applicable confidentiality obligations and Accenture’s policies, including Policy 69 – Confidentiality, when using the tool and information in the tool.  Information in the tool can only be shared with those authorized to receive it. If you are downloading/exporting Confidential Information to another file or report, you must label that file or report as Accenture Confidential. If you have any questions, please email your question to <a href="mailto:confidentiality@accenture.com?cc=AutomationJourney_Dev@accenture.com&subject=Confidential Information Query (Asset: myWizard)&body=Hi, %0A%0AI have the below query, could you please help on this %0A%0A[TYPE YOUR QUERY HERE]">confidentiality@accenture.com</a> or contact your Leadership. Thank you!');

            if (id_token != undefined && id_token != '') {
              this.loadScopeSelector();
            }
            else {
              console.log('*****id_token check id_token!=undefined && id_token************');
            }
          }
        }).catch(error => {
          console.log("catch block error");
        });
      }
      else {
        console.log('*****User Already login************');
        if (localStorage.getItem('msal.idtoken') != undefined && localStorage.getItem('msal.idtoken') != '')
          this.ajWebService.showConfidentialityMessage('You are using an Accenture proprietary tool ("myWizard"). You understand that this tool may contain  <strong>ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION</strong>. You are obligated to adhere to applicable confidentiality obligations and Accenture’s policies, including Policy 69 – Confidentiality, when using the tool and information in the tool.  Information in the tool can only be shared with those authorized to receive it. If you are downloading/exporting Confidential Information to another file or report, you must label that file or report as Accenture Confidential. If you have any questions, please email your question to <a href="mailto:confidentiality@accenture.com?cc=AutomationJourney_Dev@accenture.com&subject=Confidential Information Query (Asset: myWizard)&body=Hi, %0A%0AI have the below query, could you please help on this %0A%0A[TYPE YOUR QUERY HERE]">confidentiality@accenture.com</a> or contact your Leadership. Thank you!');

        this.token = localStorage.getItem("msal.idtoken");
        this.payload_token = this.getDecodedAccessToken(this.token); // decodeing tokan here
        //console.log('userName---', this.payload_token.preferred_username);
        this.ajWebService.enterpriseId = this.payload_token.preferred_username.split('@')[0];
        this.loadScopeSelector();
      }
      // try {

      //   this.authMSALService.loginRedirect();
      //   this.authMSALService.instance.handleRedirectPromise().then(res => {
      //     if (res != null && res.account != null) {
      //       console.log("Sanraj-Malik-Test")
      //       console.log(res);
      //       this.authMSALService.instance.setActiveAccount(res.account)

      //     }
      //     else {
      //       console.log(res);
      //       console.log("login not happening");
      //     }
      //   }).catch(err => {
      //     console.log("Catch block error")
      //     console.log(err)
      //   })

      //   //PREVIOUS LOGINFAILURE CODE
      //   // this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      //   //   this.ajWebService.showErrorMessage("Some error in authentication. Please allow popup window for authenticating the user.");
      //   // });

      //   this.broadcastService.msalSubject$
      //     .pipe(
      //       // Optional filtering of events.
      //       filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      //       takeUntil(this._destroying$)
      //     )
      //     .subscribe((result: EventMessage) => {
      //       // Do something with the result
      //       //console.log(payload);
      //       const payload = result.payload as AuthenticationResult;
      //       sessionStorage.setItem('token', payload.accessToken);
      //       this.payload_token = this.getDecodedAccessToken(payload.accessToken); // decodeing tokan here
      //       sessionStorage.setItem('userId', this.payload_token.preferred_username);
      //       //console.log('userName---', this.payload_token.preferred_username);
      //       this.ajWebService.enterpriseId = this.payload_token.preferred_username.split('@')[0];
      //       this.loadScopeSelector();
      //       //this.route.navigate(["/home"]);
      //       //  this.checkUserRegistration();
      //     });

      //   if (localStorage.getItem('msal.idtoken') != undefined && localStorage.getItem('msal.idtoken') != '')
      //     this.ajWebService.showConfidentialityMessage('You are using an Accenture proprietary tool ("myWizard"). You understand that this tool may contain  <strong>ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION</strong>. You are obligated to adhere to applicable confidentiality obligations and Accenture’s policies, including Policy 69 – Confidentiality, when using the tool and information in the tool.  Information in the tool can only be shared with those authorized to receive it. If you are downloading/exporting Confidential Information to another file or report, you must label that file or report as Accenture Confidential. If you have any questions, please email your question to <a href="mailto:confidentiality@accenture.com?cc=AutomationJourney_Dev@accenture.com&subject=Confidential Information Query (Asset: myWizard)&body=Hi, %0A%0AI have the below query, could you please help on this %0A%0A[TYPE YOUR QUERY HERE]">confidentiality@accenture.com</a> or contact your Leadership. Thank you!');

      //   this.token = localStorage.getItem("msal.idtoken");
      //   this.payload_token = this.getDecodedAccessToken(this.token); // decodeing tokan here
      //   //console.log('userName---', this.payload_token.preferred_username);
      //   this.ajWebService.enterpriseId = this.payload_token.preferred_username.split('@')[0];
      //   this.loadScopeSelector();
      //   //this.route.navigate(["/home"]);

      // }
      // catch (error) {
      //   console.log(error);
      // }
    }
    else {
      this.E2E = window.location.href.split("/").pop();
      sessionStorage.setItem('E2E', this.E2E);
      this.ajWebService.enterpriseId = 'utkarsh.gaur.singh';
      this.loadScopeSelector();

      //this.populateClientDetails();
      //this.route.navigate(["/home"]);
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (e) {
      //console.log(e);
      return null;

    }
  }

  toggleScopeSelector() {
    this.showScopeSelector == '' ? this.showScopeSelector = 'show' : this.showScopeSelector = '';
    this.clickedonheadervalues = true;
  }

  closeScopeSelector(clicked: boolean) {
    clicked == true ? this.clickedonheadervalues = false : this.showScopeSelector = "";
  }

  closeScopeSelectorApply(clicked: boolean) {
    $(".form-control.form-control-sm").val("");
    this.showScopeSelector = "";

  }

  loadScopeSelector() {
    this.getClientHierarchy("loadScopeSelector");
  }

  checkWS5Class(val: any) {
    if ($('#ws5' + val).hasClass('selectedmenu'))
      $('#ws5' + val).removeClass('selectedmenu');
    else
      $('#ws5' + val).addClass('selectedmenu');
  }

  checkCms5Class(val: any) {
    //   if($('#cms5'+ val).hasClass('selectedmenu'))
    //   $('#cms5'+ val).removeClass('selectedmenu');
    //  else
    $('#cms5' + val).addClass('selectedmenu');
  }

  checkWS4Class(val: any) {
    if ($('#ws4' + val).hasClass('selectedmenu'))
      $('#ws4' + val).removeClass('selectedmenu');
    else
      $('#ws4' + val).addClass('selectedmenu');

  }

  checkCms4Class(val: any) {
    //   if($('#cms4'+ val).hasClass('selectedmenu'))
    //   $('#cms4'+ val).removeClass('selectedmenu');
    //  else
    $('#cms4' + val).addClass('selectedmenu');
  }

  checkWS3Class(val: any) {
    if ($('#ws3' + val).hasClass('selectedmenu'))
      $('#ws3' + val).removeClass('selectedmenu');
    else
      $('#ws3' + val).addClass('selectedmenu');

  }

  checkCms3Class(val: any) {
    //   if($('#cms3'+ val).hasClass('selectedmenu'))
    //   $('#cms3'+ val).removeClass('selectedmenu');
    //  else
    $('#cms3' + val).addClass('selectedmenu');
  }

  checkWS2Class(val: any) {
    if ($('#ws2' + val).hasClass('selectedmenu'))
      $('#ws2' + val).removeClass('selectedmenu');
    else
      $('#ws2' + val).addClass('selectedmenu');

  }

  checkCms2Class(val: any) {
    //   if($('#cms2'+ val).hasClass('selectedmenu'))
    //   $('#cms2'+ val).removeClass('selectedmenu');
    //  else
    $('#cms2' + val).addClass('selectedmenu');
  }

  checkWS1Class(val: any) {
    if ($('#ws1' + val).hasClass('selectedmenu'))
      $('#ws1' + val).removeClass('selectedmenu');
    else
      $('#ws1' + val).addClass('selectedmenu');

  }

  checkCms1Class(val: any) {
    //   if($('#cms1'+ val).hasClass('selectedmenu'))
    //   $('#cms1'+ val).removeClass('selectedmenu');
    //  else
    $('#cms1' + val).addClass('selectedmenu');
  }

  checkWS0Class(val: any) {
    if ($('#ws0' + val).hasClass('selectedmenu'))
      $('#ws0' + val).removeClass('selectedmenu');
    else
      $('#ws0' + val).addClass('selectedmenu');

  }

  checke2eclass() {
    if ($('#e2e1').hasClass('selectedmenu'))
      $('#e2e1').removeClass('selectedmenu');
    else
      $('#e2e1').addClass('selectedmenu');

  }

  checke2eclass1() {
    if ($('#e2e2').hasClass('selectedmenu'))
      $('#e2e2').removeClass('selectedmenu');
    else
      $('#e2e2').addClass('selectedmenu');
  }

  checke2eclass2() {
    if ($('#e2e3').hasClass('selectedmenu'))
      $('#e2e3').removeClass('selectedmenu');
    else
      $('#e2e3').addClass('selectedmenu');
  }

  checke2eclass3() {
    if ($('#e2e4').hasClass('selectedmenu'))
      $('#e2e4').removeClass('selectedmenu');
    else
      $('#e2e4').addClass('selectedmenu');
  }

  checkcontract1() {
    if ($('#contract1').hasClass('selectedmenu'))
      $('#contract1').removeClass('selectedmenu');
    else
      $('#contract1').addClass('selectedmenu');

  }

  checkcontract2() {
    if ($('#contract2').hasClass('selectedmenu'))
      $('#contract2').removeClass('selectedmenu');
    else
      $('#contract2').addClass('selectedmenu');

  }

  checkcontract3() {
    if ($('#contract3').hasClass('selectedmenu'))
      $('#contract3').removeClass('selectedmenu');
    else
      $('#contract3').addClass('selectedmenu');

  }

  FillAllSelectedCMSWS = function () {
    // this.ajWebService.LoadOpportunityRequest = [{ EndToEndUId: this.ajWebService.endToEndUId, UId: this.ajWebService.endToEndUId, Name: this.endToEndName, Type: "EndToEnd", ParentUId: null, IsClosed: false }];
    this.LoadOpportunityRequest.forEach((obj, key) => {
      if (obj.Type == 'CMS') {
        var index = this.SelectedFilterCMSUIds.indexOf(obj.UId);
        if (index == -1) {
          this.SelectedFilterCMSUIds.push(obj.UId);
        }
      }
      else if (obj.Type == 'Workstream') {
        var index = this.SelectedFilterWSUIds.indexOf(obj.UId);
        if (index == -1) {
          this.SelectedFilterWSUIds.push(obj.UId);
        }
      }
      else if (obj.Type == 'EndToEnd') {
        var index = this.SelectedFilterEToEId.indexOf(obj.UId);
        if (index == -1) {
          this.SelectedFilterEToEId.push(obj.UId);
        }
      }
    });
  }
  getClientHierarchy(fromMethod: string) {
    this.SpinnerService.show();


    this.ajWebService.endToEndUId = this.E2E;
    // this.getWorkStreams();
    // this.getProjectAttributes();     

    this.ajWebService.getClientHierarchy(this.ajWebService.enterpriseId, this.ajWebService.endToEndUId.toLowerCase(), null)
      .subscribe(data => {
        this.scopeSelector = data;
        this.getAJUserAccess(data);
        // this.ajWebService.updateScopeSelector.emit({
        //   cmsOrWS: this.cmsOrWS,
        //   cmsOrWSUid: this.cmsOrWSUid,
        //   wsUid: this.wsUid,
        //   cmsOrWSId: this.cmsOrWSId
        // });
        // for (var i = 0; i < this.scopeSelector["Clients"].length; i++)
        //   for (var j = 0; j < this.scopeSelector["Clients"][i]["ContractOpportunityInitiativeGroups"].length; j++)
        //     if (this.scopeSelector["Clients"][i]["ContractOpportunityInitiativeGroups"][j]["EndToEndUId"] = this.ajWebService.endToEndUId)
        //       if (this.clientList.indexOf(this.scopeSelector["Clients"][i]) < 0)
        //         this.clientList.push(this.scopeSelector["Clients"][i]);
        // this.selectedClientId = this.scopeSelector["SelectedClient"].Id;
        // this.selectedClientName = this.scopeSelector["SelectedClient"].Name;

        // if (fromMethod == "loadScopeSelector") {
        // this.FilterContractOpportunityInitiatives();
        // this.clientName = this.scopeSelector["SelectedClient"].Name.replace('"', '').replace('"', '');
        // this.selectedClientName = this.scopeSelector["SelectedClient"].Name.replace('"', '').replace('"', '');
        // this.endToEndName = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndName;
        // this.ProjectId = null;//this.activeProjectId;
        // this.isAuthorised = true;
        // this.scopeDisplayTitle = this.clientName + '|' + this.endToEndName;
        // }
        // else if (fromMethod == "getClients") {
        //   var a = this.scopeSelector["Clients"].length;
        //   if (this.scopeSelector["Clients"].length == 0 || this.scopeSelector["SelectedClient"] == null) {
        //     //$('#scopeSelector').hide();
        //     this.ajWebService.isError = true;
        //     this.ajWebService.ErrorMessage = 'Access Denied';
        //   } 
        //   else
        //     this.getAJUserAccess(data);
        // }
        //if(this.currentSelectedEndToEnd != undefined)

        this.createRequestEndToEnd(this.currentSelectedEndToEnd);
        this.SpinnerService.hide();
      },
        error => {
          console.log("catch block error");
        })
  }

  getAJUserAccess(data1) {
    this.ajWebService.getAJUserAccess(this.ajWebService.endToEndUId.toLowerCase())
      .subscribe(data => {
        if (data) {
          this.getSelectedEndToEnd(data1, this.ajWebService.endToEndUId.toLowerCase());

          if (this.scopeSelector["SelectedClient"] == null) {
            bootbox.alert({
              message: "User doesn't have access to the specific client."
            }).find(".btn-primary").removeClass("btn").css({
              padding: "6px 12px",
              "border-radius": "0rem",
              border: "1px solid transparent"
            }).find(".modal-content").css({
              "border-radius": "0rem"
            });
            window.stop();
          }
          else {
            this.ajWebService.cmsData = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.CMSLevelOne;
            if (this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndUId.toLowerCase() != this.ajWebService.endToEndUId.toLowerCase()) {
              // $('#scopeSelector').hide();
              this.ajWebService.isError = true;
              this.ajWebService.ErrorMessage = 'Please select valid client  to continue';
              this.SpinnerService.hide();
            }
            else {
              var roleTypeId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.RoleTypeCode;
              var roleAccessCode = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.RoleAccessCode;
              var endToEndUId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndUId;
              if ((roleTypeId != UserRoleType.TeamMember && roleTypeId != UserRoleType.ConfigurationManager)
                || (roleAccessCode != null && roleAccessCode == "E")) {
                this.showReviewPortfolio = true;
              }
              if (roleTypeId != "" && roleTypeId != UserRoleType.TeamMember)
                this.showManageProject = true;
              if (endToEndUId == "00000000-0000-0000-0000-000000000000" || endToEndUId == null ||
                endToEndUId == "" || endToEndUId == "null") {
                this.showExecuteTasks = false;
              }
              else
                this.showExecuteTasks = true;
              if (this.showReviewPortfolio == true && this.ajWebService.tabCode == 'RP')
                this.isAuthorised = true;
              else if (this.showManageProject == true && this.ajWebService.tabCode == 'MW')
                this.isAuthorised = true;
              else if (this.showExecuteTasks == true && this.ajWebService.tabCode == 'ET')
                this.isAuthorised = true;
              else if (this.showExecuteTasks == true && this.ajWebService.tabCode == 'GOV')
                this.isAuthorised = true;
              if (this.isAuthorised == false) {
                this.ajWebService.isError = true;
                this.ajWebService.ErrorMessage = 'Access Denied';
              }
              else
                this.getWorkStreams();
            }
          }
        }
        else {
          //$('#scopeSelector').hide();
          this.ajWebService.isError = true;
          this.ajWebService.ErrorMessage = 'Access denied, Please contact your Delivery Lead';
          this.SpinnerService.hide();
        }
      },
        error => {
          console.log("catch block error");
        })
  }

  // scope selector start
  getWorkStreams() {
    this.ajWebService.getWorkStreams(this.ajWebService.endToEndUId.toLowerCase()).subscribe(data => {
      this.SetWorkstream(data);
      this.ClientId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.ClientId.toString();
      this.FilterContractOpportunityInitiatives();
      this.clientName = this.scopeSelector["SelectedClient"].Name;
      this.clientNameLimit = this.clientName.length >= 20 ? this.clientName.slice(0, 19) + "..." : this.clientName;

      this.endToEndName = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndName;
      this.endToEndNameLimit = this.endToEndName.length >= 20 ? this.endToEndName.slice(0, 19) + "..." : this.endToEndName;
      //this.activeProjectId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndUId.toString();
      this.ajWebService.endToEndUId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndUId;
      //$rootScope.endToEndId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndId;
      this.ProjectId = null;//this.activeProjectId;
      //$("#EndToEndUId").val($rootScope.EndToEndUId);
      this.isAuthorised = true;

      this.scopeDisplayTitle = this.clientName + ' | ' + this.endToEndName;
      this.scopeDisplayTitleLimit = this.clientNameLimit + ' | ' + this.endToEndNameLimit;
      this.clientUser = true;
      this.CheckIsFDSMigrated();
    },
      error => {
        console.log("catch block error");
      })
  }

  getProjectAttributes() {
    this.ajWebService.getProjectAttributes(this.ajWebService.endToEndUId.toLowerCase())
      .subscribe(response => {
        this.DeliveryConstructAttributeForCMS = response["DeliveryConstructAttributeForCMS"];

        if (response["DeliveryConstructAttributeForEndToEnd"][0].FDSMasterIndicator && response["DeliveryConstructAttributeForEndToEnd"][0].FDSMasterIndicator == 'Y') {
          //console.log("CheckIsFDSMigrated - FDSMasterIndicator " + response["DeliveryConstructAttributeForEndToEnd"][0].FDSMasterIndicator);
          this.clientUser = true;

          if (response["DeliveryConstructAttributeForEndToEnd"][0].DeliveryTypeShortName.indexOf("AM") > -1)
            this.ajWebService.DAvisible = true;
          else if ((response["DeliveryConstructAttributeForEndToEnd"][0].DeliveryTypeShortName.indexOf("AD") > -1) ||
            (response["DeliveryConstructAttributeForEndToEnd"][0].DeliveryTypeShortName.indexOf("Agile") > -1) ||
            (response["DeliveryConstructAttributeForEndToEnd"][0].DeliveryTypeShortName.indexOf("Testing") > -1) ||
            (response["DeliveryConstructAttributeForEndToEnd"][0].DeliveryTypeShortName.indexOf("Test") > -1)) {
            this.ajWebService.DAvisible = false;
          }
          else {
            this.ajWebService.LaunchAnalyservisible = false;
            this.ajWebService.DAvisible = false;
          }

          if (response["DeliveryConstructAttributeForEndToEnd"][0].FDSMasterIndicator && response["DeliveryConstructAttributeForEndToEnd"][0].FDSMasterIndicator == 'Y')
            this.clientUser = false;
          this.setCMSStatus();
          this.createRequestEndToEnd(this.currentSelectedEndToEnd);
          this.ajWebService.ScopeSelectorLoaded = true;
          this.SpinnerService.hide();
          //this.route.navigate(["/home"]);
        }
        else {
          // $('#scopeSelector').hide();
          this.isAuthorised = false;
          this.ajWebService.ScopeSelectorLoaded = false;
          this.clientUser = false;
          this.ajWebService.isError = true;
          this.ajWebService.ErrorMessage = 'This project is yet to be migrated to Final';
          this.SpinnerService.hide();
        }
      },
        error => {
          console.log("catch block error");
        })
  }

  // scope selector end

  //Logic Implementation
  setTitle() {
    var title = "";
    var titleLimit = "";
    title = this.clientName;
    title = this.endToEndName ? title + " | " + this.endToEndName : title;
    title = this.cmsName ? title + " | " + this.cmsName : title;
    title = this.workstreamName ? title + " | " + this.workstreamName : title;
    this.scopeDisplayTitle = title;

    titleLimit = this.clientName.length >= 20 ? this.clientName.slice(0, 19) + "..." : this.clientName;
    titleLimit = this.endToEndName ? titleLimit + " | " + (this.endToEndName.length >= 20 ? this.endToEndName.slice(0, 19) + "..." : this.endToEndName) : titleLimit;
    titleLimit = this.cmsName ? titleLimit + " | " + (this.cmsName.length >= 20 ? this.cmsName.slice(0, 19) + "..." : this.cmsName) : titleLimit;
    titleLimit = this.workstreamName ? titleLimit + " | " + (this.workstreamName.length >= 20 ? this.workstreamName.slice(0, 19) + "..." : this.workstreamName) : titleLimit;
    this.scopeDisplayTitleLimit = titleLimit;


  }

  FilterContractOpportunityInitiatives() {
    this.scopeSelector["Clients"] = {};
    var clientsArray = [];
    clientsArray.push(this.scopeSelector["SelectedClient"]);
    this.scopeSelector["Clients"] = clientsArray;
    var client = this.scopeSelector["SelectedClient"];
    if (client != null && client != undefined) {
      if (client.ContractOpportunityInitiativeGroups == null || client.ContractOpportunityInitiativeGroups == undefined || client.ContractOpportunityInitiativeGroups.length == 0) {
        //this.ShowLoader = true;
      }
      else {
        this.contractOpportunityInitiativeGroups = [];
        var contractOpportunityInitiativeGroupsArray = []
        var e2e = this.ajWebService.endToEndUId.toLowerCase();
        client.ContractOpportunityInitiativeGroups.forEach((cio, key) => {
          if (cio.EndToEndUId == e2e)
            contractOpportunityInitiativeGroupsArray.push(cio);
        });
        this.contractOpportunityInitiativeGroups = contractOpportunityInitiativeGroupsArray;
        if (this.contractOpportunityInitiativeGroups.length > 0)
          for (var i = 0; i < this.contractOpportunityInitiativeGroups.length; i++)
            if (this.contractOpportunityInitiativeGroups[i]["StandaloneWorkstreams"].length > 0)
              this.contractOpportunityInitiativeGroups[i]["StandaloneWorkstreams"].sort((a, b) => a.Workstream.toLowerCase() > b.Workstream.toLowerCase() ? -1 : 1)
        this.SetCurrentSelectedItem();
      }
    }
  }

  SetCurrentSelectedItem() {
    var selectedClient = this.scopeSelector["SelectedClient"];
    if (selectedClient != null && selectedClient.SelectedContractOpportunityInitiativeGroup != null) {
      this.currentSelectedEndToEnd = selectedClient.SelectedContractOpportunityInitiativeGroup.IsGroup ?
        selectedClient.SelectedContractOpportunityInitiativeGroup.SelectedContractOpportunityInitiative :
        selectedClient.SelectedContractOpportunityInitiativeGroup;
      this.currentSelectedCMS = this.currentSelectedEndToEnd.SelectedCMS;
      this.currentSelectedWorkstream = this.currentSelectedEndToEnd.SelectedWorkstream;
    }
  }

  populateClientDetails() {
    this.SpinnerService.show();
    if (this.ajWebService.enterpriseId && this.ajWebService.enterpriseId != undefined && (window.location.href.toLowerCase().indexOf('review') == -1)) {
      this.ajWebService.role = "Developer";
      if (this.ajWebService.tabCode != 'GOV' && this.ajWebService.tabCode != 'ST' && window.location.href.indexOf('accessDenied') == -1) {
        if (this.ajWebService.tabCode == 'RP')
          this.getClients(window.location.href.split('/').pop(), null, null);
        else
          this.getClients(null, null, window.location.href.split('/').pop());
      }
      else {
        // $('#scopeSelector').hide();  //check id
        this.SpinnerService.hide();
        this.ProjectId = window.location.href.split('/').pop();
        this.isAuthorised = true;
        this.ajWebService.ScopeSelectorLoaded = true;
        this.clientUser = false;
      }
    }
    else if (window.location.href.toLowerCase().indexOf('review') > -1) {
      this.ajWebService.role = "Reviewer";
      this.SpinnerService.show();
      // $('#scopeSelector').hide();
    }
  }

  getClients(clientId: string, engagementId: string, projectId: string) {
    this.showReviewPortfolio = false;
    this.showManageProject = false;
    this.showExecuteTasks = false;
    this.ajWebService.ScopeSelectorLoaded = false;
    this.getClientHierarchy("getClients");
  }

  getSelectedEndToEnd(source, E2EUId) {
    this.contractOpportunityInitiativeGroups = [];
    this.scopeSelector = source;
    var stopParentLoop = false;
    var stopChildLoop = false;
    source.Clients.forEach((client, key) => {
      var a = this.scopeSelector;

      if (!stopParentLoop) {
        client.ContractOpportunityInitiativeGroups.forEach((COIG, key) => {
          if (!stopChildLoop) {
            if (COIG.IsGroup == true) {
              COIG.ContractOpportunityInitiatives.forEach((COI, key) => {
                if (!stopChildLoop) {
                  if (COI.EndToEndUId.toLowerCase() == E2EUId.toLowerCase()) {
                    this.scopeSelector["SelectedClient"] = client;
                    this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup = COI;
                    //console.log(COI.EndToEndName + COI.EndToEndUId);
                    COIG.IsChildCollapsed = false;
                    COI.IsSelected = true;
                    stopChildLoop = true;
                    stopParentLoop = true;
                  }
                }
              });
            }
            else if (COIG.EndToEndUId.toLowerCase() == E2EUId.toLowerCase()) {

              this.clientList.push(client);
              this.ajWebService.clientList = client.Name;
              this.selectedClientId = client.Id;
              this.selecteEndtoEndName = COIG.EndToEndName;
              this.selectedClientName = client.Name;

              this.scopeSelector["SelectedClient"] = client;
              this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup = COIG;
              this.contractOpportunityInitiativeGroups.push(COIG);
              COIG.IsSelected = true;
              //console.log(COIG.EndToEndName + COIG.EndToEndUId);


              stopChildLoop = true;
              stopParentLoop = true;
            }
          }
        });
      }
    });
  }

  SetWorkstream(currentSelectedWorkstream) {
    var selectedClient = this.scopeSelector["SelectedClient"];
    if (selectedClient != null && selectedClient.SelectedContractOpportunityInitiativeGroup != null) {
      this.currentSelectedEndToEnd = selectedClient.SelectedContractOpportunityInitiativeGroup.IsGroup ?
        selectedClient.SelectedContractOpportunityInitiativeGroup.SelectedContractOpportunityInitiative :
        selectedClient.SelectedContractOpportunityInitiativeGroup;
    }
    currentSelectedWorkstream.forEach((workstream, key) => {
      this.currentSelectedEndToEnd.CMSLevelOne.forEach((CMSLevelOne, LevelOneKey) => {
        if (CMSLevelOne.CMSUId == workstream.CMSUId) {
          this.currentSelectedEndToEnd.CMSLevelOne[LevelOneKey].Workstreams.push(workstream);
        }
        CMSLevelOne.CMSLevelTwo.forEach((CMSLevelTwo, LevelTwoKey) => {
          if (CMSLevelTwo.CMSUId == workstream.CMSUId) {
            this.currentSelectedEndToEnd.CMSLevelOne[LevelOneKey].CMSLevelTwo[LevelTwoKey].Workstreams.push(workstream);
          }
          CMSLevelTwo.CMSLevelThree.forEach((CMSLevelThree, LevelThreeKey) => {
            if (CMSLevelThree.CMSUId == workstream.CMSUId) {
              this.currentSelectedEndToEnd.CMSLevelOne[LevelOneKey].CMSLevelTwo[LevelTwoKey].CMSLevelThree[LevelThreeKey].Workstreams.push(workstream);
            }
            CMSLevelThree.CMSLevelFour.forEach((CMSLevelFour, LevelFourKey) => {
              if (CMSLevelFour.CMSUId == workstream.CMSUId) {
                this.currentSelectedEndToEnd.CMSLevelOne[LevelOneKey].CMSLevelTwo[LevelTwoKey].CMSLevelThree[LevelThreeKey].CMSLevelFour[LevelFourKey].Workstreams.push(workstream);
              }
              CMSLevelFour.CMSLevelFive.forEach((CMSLevelFive, LevelFiveKey) => {
                if (CMSLevelFive.CMSUId == workstream.CMSUId) {
                  this.currentSelectedEndToEnd.CMSLevelOne[LevelOneKey].CMSLevelTwo[LevelTwoKey].CMSLevelThree[LevelThreeKey].CMSLevelFour[LevelFourKey].CMSLevelFive[LevelFiveKey].Workstreams.push(workstream);
                }
              });
            });
          });
        });
      });
      if (workstream.CMSUId == "00000000-0000-0000-0000-000000000000") {
        this.currentSelectedEndToEnd.StandaloneWorkstreams.push(workstream);
      }
    });
  }

  CheckIsFDSMigrated() {
    this.SpinnerService.show();
    if (this.ajWebService.endToEndUId.toLowerCase()) {
      this.getProjectAttributes();
    }
    else
      console.log("CheckIsFDSMigrated endToEndUId is null or empty");
  }

  SelectCMS(event, endToEndGroup, endToEnd, cms1, cms2?: any, cms3?: any, cms4?: any, cms5?: any) {
    this.currentSelectedEndToEnd = null;
    this.currentSelectedCMS = null;
    this.currentSelectedWorkstream = null;
    this.currentSelectedEndToEnd = endToEnd;
    if (cms5) {
      var isChildCollapsed = !cms5.IsChildCollapsed;
    }
    else if (cms4) {
      var isChildCollapsed = !cms4.IsChildCollapsed;
    }
    else if (cms3) {
      var isChildCollapsed = !cms3.IsChildCollapsed;
    }
    else if (cms2) {
      var isChildCollapsed = !cms2.IsChildCollapsed;
    }
    else if (cms1) {
      var isChildCollapsed = !cms1.IsChildCollapsed;
    }
    // this.ResetScopeSelectorCollapsed();
    if (cms5) {
      cms5.IsChildCollapsed = isChildCollapsed;
      cms5.IsSelected = this.IsCMSSelectable ? true : false;
      this.currentSelectedCMS = cms5;
      /* if (!isChildCollapsed) {
        cms4.IsChildCollapsed = isChildCollapsed;
        cms3.IsChildCollapsed = isChildCollapsed;
        cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        cms4.IsChildCollapsed = !isChildCollapsed;
        cms3.IsChildCollapsed = !isChildCollapsed;
        cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed;
      } */
    }
    else if (cms4) {
      cms4.IsChildCollapsed = isChildCollapsed;
      cms4.IsSelected = this.IsCMSSelectable ? true : false;

      this.currentSelectedCMS = cms4;
      if (!isChildCollapsed) {
        /* cms3.IsChildCollapsed = isChildCollapsed;
        cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed; */
      }
      else {
        /* cms3.IsChildCollapsed = !isChildCollapsed;
        cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed; */
      }
    }
    else if (cms3) {
      cms3.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms3;
      cms3.IsSelected = this.IsCMSSelectable ? true : false;

      if (!isChildCollapsed) {
        /* cms2.IsChildCollapsed = isChildCollapsed;
        cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed; */
      }
      else {
        /* cms2.IsChildCollapsed = !isChildCollapsed;
        cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed; */
      }
    }
    else if (cms2) {
      cms2.IsChildCollapsed = isChildCollapsed;
      cms2.IsSelected = this.IsCMSSelectable ? true : false;

      this.currentSelectedCMS = cms2;
      if (!isChildCollapsed) {
        /* cms1.IsChildCollapsed = isChildCollapsed;
        endToEnd.IsChildCollapsed = isChildCollapsed; */
      }
      else {
        /* cms1.IsChildCollapsed = !isChildCollapsed;
        endToEnd.IsChildCollapsed = !isChildCollapsed; */
      }
    }
    else if (cms1) {
      cms1.IsChildCollapsed = isChildCollapsed;
      cms1.IsSelected = this.IsCMSSelectable ? true : false;
      this.currentSelectedCMS = cms1;
      if (!isChildCollapsed) {
        //  endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        //  endToEnd.IsChildCollapsed = !isChildCollapsed;
      }
    }

    if (endToEndGroup) {
      if (!isChildCollapsed) {
        endToEndGroup.IsChildCollapsed = isChildCollapsed;
      }
      else {
        endToEndGroup.IsChildCollapsed = !isChildCollapsed;
      }
    }

    if (this.IsCMSSelectable) {
      this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, this.currentSelectedCMS, null);
    }
    else {
      this.currentSelectedCMS = null;
      endToEnd.IsSelected = true;
    }
  }

  createRequestEndToEnd(selectedEndToEnd: any) {
    this.ajWebService.LoadOpportunityRequest = [{ EndToEndUId: this.ajWebService.endToEndUId.toLowerCase(), UId: this.ajWebService.endToEndUId.toLowerCase(), Name: this.endToEndName, Type: "EndToEnd", ParentUId: null, IsClosed: false }];

    if (selectedEndToEnd != undefined) {
      selectedEndToEnd.StandaloneWorkstreams.forEach((SWs, LOneWorkstreamKey) => {
        this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, SWs.WorkstreamUId, SWs.Workstream, "Workstream", selectedEndToEnd.EndToEndUId, (SWs.WorkstreamStatus == "Closed"));
      });
    }
    this.createRequestCMSLevelOne(selectedEndToEnd);
    this.ajWebService.FilteredCMSWSRequest = this.ajWebService.LoadOpportunityRequest;
    this.ajWebService.setLoadOpportuityRequest(this.ajWebService.LoadOpportunityRequest);
  }

  createRequestCMSLevelOne(selectedEndToEnd: any) {
    if (selectedEndToEnd != undefined)
      selectedEndToEnd.CMSLevelOne.forEach((CMSLevelOne, LevelOneKey) => {
        this.CMSLevelOneUId = CMSLevelOne.CMSUId;
        this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, selectedEndToEnd.CMSLevelOne[LevelOneKey].CMSUId, selectedEndToEnd.CMSLevelOne[LevelOneKey].Name, "CMS", selectedEndToEnd.EndToEndUId, (selectedEndToEnd.CMSLevelOne[LevelOneKey].ClosedFlag == "Y" ? true : false));
        if (CMSLevelOne.ClosedFlag == 'N') {
          selectedEndToEnd.CMSLevelOne[LevelOneKey].Workstreams.filter(x => x.WorkstreamStatus != "Closed").forEach((LOneWs, LOneWorkstreamKey) => {
            this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LOneWs.WorkstreamUId, LOneWs.Workstream, "Workstream", selectedEndToEnd.CMSLevelOne[LevelOneKey].CMSUId, (LOneWs.WorkstreamStatus == "Closed"));
          });
        }
        this.createRequestCMSLevelTwo(selectedEndToEnd, CMSLevelOne.CMSLevelTwo);
      });
  }

  createRequestCMSLevelTwo(selectedEndToEnd, CMSLevelTwoArray) {
    CMSLevelTwoArray.forEach((CMSLevelTwo, LevelTwoKey) => {
      this.CMSLevelTwoUId = CMSLevelTwo.CMSUId;
      this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, CMSLevelTwoArray[LevelTwoKey].CMSUId, CMSLevelTwoArray[LevelTwoKey].Name, "CMS", this.CMSLevelOneUId, (CMSLevelTwoArray[LevelTwoKey].ClosedFlag == "Y" ? true : false));
      if (CMSLevelTwoArray[LevelTwoKey].ClosedFlag == 'N') {
        CMSLevelTwoArray[LevelTwoKey].Workstreams.filter(x => x.WorkstreamStatus != "Closed").forEach((LTwoWs, LTwoWorkstreamKey) => {
          this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LTwoWs.WorkstreamUId, LTwoWs.Workstream, "Workstream", CMSLevelTwoArray[LevelTwoKey].CMSUId, (LTwoWs.WorkstreamStatus == "Closed"));
        });
      }
      this.createRequestCMSLevelThree(selectedEndToEnd, CMSLevelTwo.CMSLevelThree);
    });
  }

  createRequestCMSLevelThree(selectedEndToEnd, CMSLevelThreeArray) {
    CMSLevelThreeArray.forEach((CMSLevelThree, LevelThreeKey) => {
      this.CMSLevelThreeUId = CMSLevelThree.CMSUId;
      this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, CMSLevelThreeArray[LevelThreeKey].CMSUId, CMSLevelThreeArray[LevelThreeKey].Name, "CMS", this.CMSLevelTwoUId, (CMSLevelThreeArray[LevelThreeKey].ClosedFlag == "Y" ? true : false));
      if (CMSLevelThreeArray[LevelThreeKey].ClosedFlag == 'N') {
        CMSLevelThreeArray[LevelThreeKey].Workstreams.filter(x => x.WorkstreamStatus != "Closed").forEach((LThreeWs, LThreeWorkstreamKey) => {
          this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LThreeWs.WorkstreamUId, LThreeWs.Workstream, "Workstream", CMSLevelThreeArray[LevelThreeKey].CMSUId, (LThreeWs.WorkstreamStatus == "Closed"));
        });
      }
      // this.createRequestCMSLevelFour(selectedEndToEnd, CMSLevelThree.CMSLevelFour);
    });
  }

  // createRequestCMSLevelFour(selectedEndToEnd, CMSLevelFourArray) {
  //   CMSLevelFourArray.forEach((CMSLevelFour, LevelFourKey) => {
  //     this.CMSLevelFourUId = CMSLevelFour.CMSUId;
  //     this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, CMSLevelFourArray[LevelFourKey].CMSUId, CMSLevelFourArray[LevelFourKey].Name, "CMS", this.CMSLevelThreeUId, (CMSLevelFourArray[LevelFourKey].ClosedFlag == "Y" ? true : false));
  //     if (CMSLevelFourArray[LevelFourKey].ClosedFlag == 'N') {
  //       CMSLevelFourArray[LevelFourKey].Workstreams.filter(x=>x.WorkstreamStatus != "Closed").forEach(function (LFourWs, LFourWorkstreamKey) {
  //         this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LFourWs.WorkstreamUId, LFourWs.Workstream, "Workstream", CMSLevelFourArray[LevelFourKey].CMSUId, (LFourWs.WorkstreamStatus == "Closed"));
  //       });
  //     }
  //     this.createRequestCMSLevelFive(selectedEndToEnd, CMSLevelFour.CMSLevelFive);
  //   });
  // }

  // createRequestCMSLevelFive(selectedEndToEnd, CMSLevelFiveArray) {
  //   CMSLevelFiveArray.forEach((CMSLevelFive, LevelFiveKey) => {
  //     this.CMSLevelFiveUId = CMSLevelFive.CMSUId;
  //     this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, CMSLevelFiveArray[LevelFiveKey].CMSUId, CMSLevelFiveArray[LevelFiveKey].Name, "CMS", this.CMSLevelFourUId, (CMSLevelFiveArray[LevelFiveKey].ClosedFlag == "Y" ? true : false));
  //     if (CMSLevelFiveArray[LevelFiveKey].ClosedFlag == 'N') {
  //       CMSLevelFiveArray[LevelFiveKey].Workstreams.filter(x=>x.WorkstreamStatus != "Closed").forEach((LFiveWs, LFiveWorkstreamKey) => {
  //         this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LFiveWs.WorkstreamUId, LFiveWs.Workstream, "Workstream", CMSLevelFiveArray[LevelFiveKey].CMSUId, (LFiveWs.WorkstreamStatus == "Closed"));
  //       });
  //     }
  //   });
  // }

  createLoadOpportunityRequest(endToEndUId: string, uId: string, name: string, type: string, parentUId: string, isClosed: boolean) {
    if (!isClosed) {
      this.ajWebService.LoadOpportunityRequest.push({
        EndToEndUId: endToEndUId,
        UId: uId,
        Name: name,
        Type: type,
        ParentUId: parentUId,
        IsClosed: isClosed
      });
    }
  }

  setCMSStatus() {
    if (this.currentSelectedEndToEnd == undefined) {
      var selectedClient = this.scopeSelector["SelectedClient"];
      if (selectedClient != null && selectedClient.SelectedContractOpportunityInitiativeGroup != null) {
        this.currentSelectedEndToEnd = selectedClient.SelectedContractOpportunityInitiativeGroup.IsGroup ?
          selectedClient.SelectedContractOpportunityInitiativeGroup.SelectedContractOpportunityInitiative :
          selectedClient.SelectedContractOpportunityInitiativeGroup;
      }
    }
    if (this.DeliveryConstructAttributeForCMS != undefined) {
      this.DeliveryConstructAttributeForCMS.forEach((CMS, CMSKey) => {
        this.currentSelectedEndToEnd.CMSLevelOne.forEach(function (CMSLevelOne, LevelOneKey) {
          if (CMS.DeliveryConstructUId == CMSLevelOne.CMSUId) {
            CMSLevelOne.ClosedFlag = (CMS.CMSStatus.toLowerCase() == "closed" ? 'Y' : 'N');
            //CMSLevelOne.CMSStatus = CMS.CMSStatus;
          }
          CMSLevelOne.CMSLevelTwo.forEach((CMSLevelTwo, LevelTwoKey) => {
            if (CMS.DeliveryConstructUId == CMSLevelTwo.CMSUId) {
              CMSLevelTwo.ClosedFlag = (CMS.CMSStatus.toLowerCase() == "closed" ? 'Y' : 'N');
              //CMSLevelTwo.CMSStatus = CMS.CMSStatus;
            }
            CMSLevelTwo.CMSLevelThree.forEach((CMSLevelThree, LevelThreeKey) => {
              if (CMS.DeliveryConstructUId == CMSLevelThree.CMSUId) {
                CMSLevelThree.ClosedFlag = (CMS.CMSStatus.toLowerCase() == "closed" ? 'Y' : 'N');
                //CMSLevelThree.CMSStatus = CMS.CMSStatus;
              }
              CMSLevelThree.CMSLevelFour.forEach((CMSLevelFour, LevelFourKey) => {
                if (CMS.DeliveryConstructUId == CMSLevelFour.CMSUId) {
                  CMSLevelFour.ClosedFlag = (CMS.CMSStatus.toLowerCase() == "closed" ? 'Y' : 'N');
                  //CMSLevelFour.CMSStatus = CMS.CMSStatus;
                }
                CMSLevelFour.CMSLevelFive.forEach((CMSLevelFive, LevelFiveKey) => {
                  if (CMS.DeliveryConstructUId == CMSLevelFive.CMSUId) {
                    CMSLevelFive.ClosedFlag = (CMS.CMSStatus.toLowerCase() == "closed" ? 'Y' : 'N');
                    //CMSLevelFive.CMSStatus = CMS.CMSStatus;
                  }
                });
              });
            });
          });
        });
      });
    }
  }


  SetSelectedEndToEndAndCMS(endToEndGroup, endtoEnd, cms, workstream) {
    var selectedClient = this.scopeSelector["SelectedClient"];
    if (endToEndGroup) {
      endToEndGroup.SelectedContractOpportunityInitiative = endtoEnd;
      endToEndGroup.SelectedContractOpportunityInitiative.SelectedCMS = null;
      endToEndGroup.SelectedContractOpportunityInitiative.SelectedWorkstream = null;
      if (cms) {
        endToEndGroup.SelectedContractOpportunityInitiative.SelectedCMS = cms;
      }
      if (workstream) {
        endToEndGroup.SelectedContractOpportunityInitiative.SelectedWorkstream = workstream;
      }
      selectedClient.SelectedContractOpportunityInitiativeGroup = endToEndGroup;

    }
    else if (endtoEnd) {
      endtoEnd.SelectedCMS = null;
      endtoEnd.SelectedWorkstream = null;
      if (cms) {
        endtoEnd.SelectedCMS = cms;
      }
      if (workstream) {
        endtoEnd.SelectedWorkstream = workstream;
      }
      selectedClient.SelectedContractOpportunityInitiativeGroup = endtoEnd;
    }

  }

  SelectEndToEnd(event, endToEnd, endToEndGroup) {
    if (endToEnd.EndToEndName == event.target.innerText) {
      this.currentSelectedEndToEnd = null;
      this.currentSelectedWorkstream = null;
      this.currentSelectedCMS = null;
      var isChildCollapsed = !endToEnd.IsChildCollapsed;
      this.ResetScopeSelectorCollapsed();
      if (endToEnd.IsGroup) {
        endToEnd.IsChildCollapsed = isChildCollapsed;
      }
      else {
        endToEnd.IsChildCollapsed = isChildCollapsed;
        this.currentSelectedEndToEnd = endToEnd;

        endToEnd.IsSelected = true;
        if (endToEndGroup) {
          if (!isChildCollapsed) {
            endToEndGroup.IsChildCollapsed = isChildCollapsed;
          }
          else {
            endToEndGroup.IsChildCollapsed = !isChildCollapsed;
          }
        }
      }

      this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, null, null);
    }
  }

  ResetScopeSelectorCollapsed() {
    this.contractOpportunityInitiativeGroups.forEach((cio, key) => {
      cio.IsChildCollapsed = true;
      cio.IsSelected = false;
      this.WorkstreamReset(cio.StandaloneWorkstreams);
      cio.ContractOpportunityInitiatives.forEach((endToEndGroup, key) => {
        endToEndGroup.IsChildCollapsed = true;
        endToEndGroup.IsSelected = false;
        this.WorkstreamReset(endToEndGroup.StandaloneWorkstreams);
        endToEndGroup.CMSLevelOne.forEach((cms1, key) => {
          cms1.IsChildCollapsed = true;
          cms1.IsSelected = false;
          this.WorkstreamReset(cms1.Workstreams);
          cms1.CMSLevelTwo.forEach((cms2, key) => {
            cms2.IsChildCollapsed = true;
            cms2.IsSelected = false;
            this.WorkstreamReset(cms2.Workstreams);
            cms2.CMSLevelThree.forEach((cms3, key) => {
              cms3.IsChildCollapsed = true;
              cms3.IsSelected = false;
              this.WorkstreamReset(cms3.Workstreams);
              // angular.forEach(cms3.CMSLevelFour, function (cms4, key) {
              //     cms4.IsChildCollapsed = true;
              //     cms4.IsSelected = false;
              //     this.WorkstreamReset(cms4.Workstreams);
              //     angular.forEach(cms4.CMSLevelFive, function (cms5, key) {
              //         cms5.IsChildCollapsed = true;
              //         cms5.IsSelected = false;
              //         this.WorkstreamReset(cms5.Workstreams);
              //     });
              // });
            });
          });
        });
      });
      cio.CMSLevelOne.forEach((cms1, key) => {
        cms1.IsChildCollapsed = true;
        cms1.IsSelected = false;
        this.WorkstreamReset(cms1.Workstreams);
        cms1.CMSLevelTwo.forEach((cms2, key) => {
          cms2.IsChildCollapsed = true;
          cms2.IsSelected = false;
          this.WorkstreamReset(cms2.Workstreams);
          cms2.CMSLevelThree.forEach((cms3, key) => {
            cms3.IsChildCollapsed = true;
            cms3.IsSelected = false;
            this.WorkstreamReset(cms3.Workstreams);
            // angular.forEach(cms3.CMSLevelFour, function (cms4, key) {
            //     cms4.IsChildCollapsed = true;
            //     cms4.IsSelected = false;
            //     this.WorkstreamReset(cms4.Workstreams);
            //     angular.forEach(cms4.CMSLevelFive, function (cms5, key) {
            //         cms5.IsChildCollapsed = true;
            //         cms5.IsSelected = false;
            //         this.WorkstreamReset(cms5.Workstreams);
            //     });
            // });
          });
        });
      });
    });

  }

  WorkstreamReset(workstreams) {
    if (workstreams.length > 0) {
      workstreams.forEach((ws, key) => {
        ws.IsSelected = false;
        // ws.IsSelected = $location.path().indexOf('CMMI') > -1 ? false : false;
      });
    }
  }

  SelectWorkstream(ws, endToEndGroup, endToEnd, cms1?: any, cms2?: any, cms3?: any, cms4?: any, cms5?: any) {

    let location = "/home/MW/" + this.E2E;
    let cmmiTeamVisible = location.indexOf('CMMI') > -1 ? true : false;
    if (cmmiTeamVisible) {
      this.IsWorkStreamSelectable = true;
      return false;
    }
    this.currentSelectedEndToEnd = endToEnd;
    this.currentSelectedWorkstream = ws;
    this.currentSelectedCMS = null;

    var isChildCollapsed = false;
    this.ResetScopeSelectorCollapsed();
    ws.IsSelected = true;

    if (cms5) {
      cms5.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms5;
      cms4.IsChildCollapsed = isChildCollapsed;
      cms3.IsChildCollapsed = isChildCollapsed;
      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms4) {
      cms4.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms4;

      cms3.IsChildCollapsed = isChildCollapsed;
      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms3) {
      cms3.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms3;

      cms2.IsChildCollapsed = isChildCollapsed;
      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms2) {
      cms2.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms2;

      cms1.IsChildCollapsed = isChildCollapsed;

    }
    else if (cms1) {
      cms1.IsChildCollapsed = isChildCollapsed;
      this.currentSelectedCMS = cms1;

    }


    endToEnd.IsChildCollapsed = isChildCollapsed;
    if (endToEndGroup) {
      endToEndGroup.IsChildCollapsed = isChildCollapsed;
    }
    this.SetSelectedEndToEndAndCMS(endToEndGroup, this.currentSelectedEndToEnd, this.currentSelectedCMS, this.currentSelectedWorkstream);

  }

  setLatestSelectedCMSorWS(cmsOrWS: string, cmsOrWSUid: any, wsUid: any, cmsOrWSId: any, endToEnd: string) {
    this.cmsOrWS = cmsOrWS;
    this.cmsOrWSUid = cmsOrWSUid;
    this.cmsOrWSId = cmsOrWSId;
    this.wsUid = wsUid;

    if ((this.lastSelectedcmsOrWS != cmsOrWS || this.lastSelectedcmsOrWSUid != cmsOrWSUid || this.lastSelectedcmsOrWSId != cmsOrWSId) && (this.lastSelectedcmsOrWS != undefined || this.lastSelectedcmsOrWSUid != undefined || this.lastSelectedcmsOrWSId != undefined))
      $(".selectedmenu").removeClass("selectedmenu");


    // if (endToEnd == 'endToEnd')
    //   $(".endToEnd").addClass('selectedmenu');
    // else
    //   $(".endToEnd").removeClass('selectedmenu');

    this.lastSelectedcmsOrWS = cmsOrWS;
    this.lastSelectedcmsOrWSUid = cmsOrWSUid;
    this.lastSelectedcmsOrWSId = cmsOrWSId;
    this.lastSelectedwsUiD = wsUid;
    if (this.lastSelectedcmsOrWS == "cms") {
      this.IomCMS = this.lastSelectedcmsOrWSUid;
      // this.AofCMS = this.lastSelectedcmsOrWSUid;
      this.IomWS = "";
      // this.AofWS = null;
    }
    else if (this.lastSelectedcmsOrWS == "ws") {
      this.IomCMS = this.lastSelectedcmsOrWSUid;
      // this.AofCMS = null;
      this.IomWS = this.lastSelectedwsUiD;
      // this.AofWS = this.lastSelectedwsUiD;
    }
    else if (this.lastSelectedcmsOrWS == "") {
      this.IomCMS = "";
      // this.AofCMS = null;
      this.IomWS = "";
      // this.AofWS = null;
    }
  }

  Apply() {
    //this.LoadOpportunityRequest = [{ EndToEndUId: this.endToEndId, UId: this.endToEndId, Name: this.endToEndName, Type: "EndToEnd", ParentUId: null, IsClosed: false }];
    $('.scope-selector.open').removeClass('open');

    // this.FilteredCMSWSRequest = this.LoadOpportunityRequest;
    // this.LoadOpportunityRequest.forEach( (obj, key) => {
    //               if (obj.Type == 'EndToEnd') {
    //                   this.EndtoEndExport = obj.Name ;
    //               }
    //             })

    this.FillAllSelectedCMSWS();
    this.SelectedScopeselectorDetails();
    this.setTitle();
    this.isActive = false;
    //CheckIsFDSMigrated();
    this.IsScopeApplied = true;
    //this.LoadOpportunity();
    //this.GetAIAARAGStatus();
    //this.GetAOFAssessments();

    if (this.cmsOrWS == "" && this.cmsOrWSUid == "" && this.cmsOrWSId == "")
      this.ajWebService.updateScopeSelector.emit(undefined);
    else
      this.ajWebService.updateScopeSelector.emit({
        cmsOrWS: this.cmsOrWS,
        cmsOrWSUid: this.cmsOrWSUid,
        wsUid: this.wsUid,
        cmsOrWSId: this.cmsOrWSId
      });
    this.ajWebService.onClear.emit();
  }




  SelectedScopeselectorDetails() {
    this.LoadOpportunityRequest = [];
    this.FilteredCMSWSRequest = [];
    this.ajWebService.LoadOpportunityRequest = [];
    this.ajWebService.FilteredCMSWSRequest = [];
    if (this.currentSelectedWorkstream) {
      var selectedWorkstream = this.currentSelectedWorkstream;
      var selectedCMS = this.currentSelectedCMS;
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.ClientId = this.scopeSelector["SelectedClient"].Id;
      this.endToEndType = selectedEndToEnd.EndToEndType;
      this.roleTypeCode = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.RoleTypeCode;
      this.roleAccessCode = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.RoleAccessCode;
      this.endToEndName = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndName;
      this.clientName = this.scopeSelector["SelectedClient"].Name;
      this.workstreamId = selectedWorkstream.WorkstreamId;
      this.workstreamUId = selectedWorkstream.WorkstreamUId;
      this.workstreamName = selectedWorkstream.Workstream;

      if (selectedCMS) {
        this.clientWMSId = selectedCMS.Id
        this.cmsName = selectedCMS.Name;
        this.clientWMSUId = selectedCMS.CMSUId;
        this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, selectedWorkstream.WorkstreamUId, selectedWorkstream.Workstream, "Workstream", selectedCMS.CMSUId, (selectedWorkstream.WorkstreamStatus == "Closed"));
      }
      else {
        this.clientWMSId = selectedWorkstream.CMSId;
        this.clientWMSUId = selectedWorkstream.CMSUId;
        this.cmsName = "";
        this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, selectedWorkstream.WorkstreamUId, selectedWorkstream.Workstream, "Workstream", selectedEndToEnd.EndToEndUId, (selectedWorkstream.WorkstreamStatus == "Closed"));
      }
    }
    else if (this.currentSelectedCMS) {
      var selectedCMS = this.currentSelectedCMS;
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.ClientId = this.scopeSelector["SelectedClient"].Id;
      this.endToEndType = selectedEndToEnd.EndToEndType;

      this.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.endToEndName = selectedEndToEnd.EndToEndName;
      this.clientName = this.scopeSelector["SelectedClient"].Name;
      this.clientWMSId = selectedCMS.Id;
      this.cmsName = selectedCMS.Name;
      this.workstreamId = 0;
      this.workstreamUId = "";
      this.workstreamName = "";

      this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, this.currentSelectedCMS.CMSUId, selectedCMS.Name, "CMS", this.currentSelectedCMS.ParentCMSUId, (selectedCMS.ClosedFlag == "Y" ? true : false));
      this.currentSelectedCMS.Workstreams.forEach((LOneWs, LOneWorkstreamKey) => {
        this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, LOneWs.WorkstreamUId, LOneWs.Workstream, "Workstream", this.currentSelectedCMS.CMSUId, (LOneWs.WorkstreamStatus == "Closed"));
      });

      if (this.currentSelectedCMS.CMSLevelTwo != undefined) {
        this.createRequestCMSLevelTwo(selectedEndToEnd, this.currentSelectedCMS.CMSLevelTwo);
      }
      else if (this.currentSelectedCMS.CMSLevelThree != undefined) {
        this.createRequestCMSLevelThree(selectedEndToEnd, this.currentSelectedCMS.CMSLevelThree);
      }
      // else if(this.currentSelectedCMS.CMSLevelFour != undefined)
      // {
      // this.CreateRequestCMSLevelFour(selectedEndToEnd, this.currentSelectedCMS.CMSLevelFour);
      // }
      // else if(this.currentSelectedCMS.CMSLevelFive != undefined)
      // {
      // this.CreateRequestCMSLevelFive(selectedEndToEnd, this.currentSelectedCMS.CMSLevelFive);
      // }
    }
    else {
      var selectedEndToEnd = this.currentSelectedEndToEnd;
      this.ClientId = this.scopeSelector["SelectedClient"].Id;
      this.endToEndType = selectedEndToEnd.EndToEndType;

      this.roleTypeCode = selectedEndToEnd.RoleTypeCode;
      this.endToEndId = selectedEndToEnd.EndToEndId;

      this.roleAccessCode = selectedEndToEnd.RoleAccessCode;
      this.endToEndName = selectedEndToEnd.EndToEndName;
      this.clientName = this.scopeSelector["SelectedClient"].Name;
      this.clientWMSId = 0;
      this.cmsName = "";
      this.ProjectId = this.scopeSelector["SelectedClient"].SelectedContractOpportunityInitiativeGroup.EndToEndUId;
      if (this.currentSelectedEndToEnd.SelectedWorkstream != null) {
        this.workstreamName = this.currentSelectedEndToEnd.SelectedWorkstream.Workstream;
      }
      else {
        this.workstreamId = 0;
        this.workstreamUId = "";
        this.workstreamName = "";
      }
      // if (this.EndToEndUId != selectedEndToEnd.EndToEndUId)
      //   $window.location.href = '/' + querystring[1] + '/' + this.tabCode + '/' + selectedEndToEnd.EndToEndUId;
      // this.EndToEndUId = selectedEndToEnd.EndToEndUId;
      // this.CreateRequestEndToEnd(selectedEndToEnd);
      // this.createLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, selectedEndToEnd.EndToEndUId, selectedEndToEnd.EndToEndName, "EndToEnd", null, false);
      // this.$broadcast("FetchServices");
    }

    this.DeliveryConstructAttributeForCMS.forEach((CMS, CMSKey) => {
      this.LoadOpportunityRequest.forEach((LORQ, LOKey) => {
        if (CMS.DeliveryConstructUId == LORQ.UId) {
          LORQ.IsClosed = (CMS.CMSStatus.toLowerCase() == "closed" ? true : false);
        }
      });
    });
    if (this.currentSelectedCMS == null && this.currentSelectedWorkstream == null) {
      this.createRequestEndToEnd(this.currentSelectedEndToEnd);
    }
    this.FilteredCMSWSRequest = this.LoadOpportunityRequest;
    this.CMSUId = ((this.currentSelectedCMS != undefined && this.currentSelectedCMS.CMSUId != undefined) ? this.currentSelectedCMS.CMSUId : "null");
    this.workstreamUId = ((this.currentSelectedWorkstream != undefined && this.currentSelectedWorkstream.WorkstreamUId != undefined) ? this.currentSelectedWorkstream.WorkstreamUId : "null");
  }


  CreateRequestEndToEnd = function (selectedEndToEnd) {
    this.LoadOpportunityRequest = [{ EndToEndUId: this.EndToEndUId, UId: this.EndToEndUId, Name: this.endToEndName, Type: "EndToEnd", ParentUId: null, IsClosed: false }];
    selectedEndToEnd.StandaloneWorkstreams.forEach((SWs, LOneWorkstreamKey) => {
      this.CreateLoadOpportunityRequest(selectedEndToEnd.EndToEndUId, SWs.WorkstreamUId, SWs.Workstream, "Workstream", selectedEndToEnd.EndToEndUId, (SWs.WorkstreamStatus == "Closed"));
    });
    this.CreateRequestCMSLevelOne(selectedEndToEnd);
    //this.FilteredCMSWSRequest = this.LoadOpportunityRequest;
  }

  login() {
    console.log("login happening here")
    this.authMSALService.loginRedirect();
    // this.authMSALService.loginPopup()
    //    .subscribe((response: AuthenticationResult) => {
    //      this.authMSALService.instance.setActiveAccount(response.account);
    //    });
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authMSALService.logout();
  }

  ngOnDestroy() {
    this.broadcastService.msalSubject$;
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  //Popup Notification Function
  initializeUserNotificationContent(): void {
    const content = {
      DataSourceUId: "00100000-0010-0000-0000-000000000000",
      TemplateUId: "00200000-0010-0000-0000-000000000000",
      ServiceUrl: "assets/UserNotification.json",
      ActiveLanguage: "en-US"//sessionStorage.getItem('Culture')
    }
    userNotification.init(content);
  }
}
