import { Component, ComponentFactoryResolver, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AJWebService } from '../../services/ajWeb.service';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
//import { DonutChartTicketComponent } from '../../DonutTicketGraph/donut-chart.component';
import { NgxSpinnerService } from "ngx-spinner";
import { common } from 'src/app/common/common';
import { AppConfigService } from 'src/services/app-config.service';

import * as c3 from 'c3';
import * as d3 from 'd3';
import * as $ from "jquery";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { data } from 'jquery';
import * as FileSaver from 'file-saver';


declare var bootbox: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('ticketDistHeatMap') ticketDistHeatMap: ElementRef;
  @ViewChild('TktDistHeatMapPopUp') TktDistHeatMapPopUp: ElementRef;

  public ExcelDataAutomationPlanner: any = [];
  public excelData: any;
  public alertsList: any;
  public dueDateInMonths: any;
  public currentPlan: any;
  public savedCostTotal: any;
  public savedEffortTotal: any;
  public endToEndUId: any;

  public clientList: any;
  public clientName: any;
  public totalAsset: any;
  public AssetLists: any[] = [];
  public backlogassetList: any[] = [];
  public inProgressassetList: any[] = [];
  public completedassetList: any[] = [];
  public cancelledassetList: any[] = [];
  public toggleChart: boolean = false;

  public LoadOpportunityRequest: any;
  // public AOFRagStatusClass: string;
  // public AOFAssessments: any;
  public previousValue: number;

  public categoryIdList = [];
  public queueId: number;
  public queueIdArray: any[] = [];
  public opportunityId: number;
  public opportunityName: string = '';
  public assetsList: string[] = [];
  public assetId: number;
  public benefitRealizationDate: Date;

  public grouping: boolean;
  public showSummary: boolean;
  public displayNavbar: boolean = true;
  public displayNavbarIconsWithText: boolean = true;

  public opportunityList: any = [];
  public opportunityQueues;
  public copyOpportunityQueues;
  public opportunityQueuesFilteredData: any[][] = [];
  public opportunityQueuesBackup: any[][] = [];
  public tempAllOL: any[] = [];
  public assetList: any[];
  public allOpportunityList: any[] = [];
  public usageValues: any[];
  public displayPopup: string = '';
  public apHomeUrl: string;

  // @ViewChild('appenHere', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  // public componentRef: ComponentRef<any>;

  public backlogList: any[] = [];
  public inProgressList: any[] = [];
  public completedList: any[] = [];
  public cancelledList: any[] = [];
  public scopedata: any;

  public backlogListBackup: any[] = [];
  public inProgressListBackup: any[] = [];
  public completedListBackup: any[] = [];
  public cancelledListBackup: any[] = [];

  public unGroupedBacklogSortOrder: boolean = false;
  public unGroupedInProgressSortOrder: boolean = false;
  public unGroupedCompletedSortOrder: boolean = false;
  public unGroupedCancelledSortOrder: boolean = false;
  public groupedOQSortOrders: any[][] = [];

  public groupedBacklogSortOrder: boolean = false;
  public groupedInProgressSortOrder: boolean = false;
  public groupedCompletedSortOrder: boolean = false;
  public groupedCancelledSortOrder: boolean = false;

  public dateValue: Date = new Date();
  public currentDate: Date = new Date();
  public monthsList: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  public yearsList: string[] = [(this.currentDate.getFullYear() - 1).toString().substr(2, 2), (this.currentDate.getFullYear()).toString().substr(2, 2)];
  public noOfMonthsArray;
  public moreThan12months: boolean = false;

  public noOfRecordsToBeSaved: number = 0;
  public noOfOQdetailsFetched: number = 0;

  public heatMapDataReceived: boolean = false;
  public supportGroupDataReceived: boolean = false;

  public BPId: number;
  public totalSavingsValueForNullAttribute: number = 0;
  public environmentDetails: any;

  public wsFilter = [{ "Workstream": "All" }];
  public selectedcmsFilter;
  public selectedcmsFlt: any;
  public source = [
    { value: undefined, name: 'All' },
    { value: "AIAA", name: 'IOM' },
    // { value: "AOF", name: 'AOF' },
    { value: "Manual", name: 'Manual' }
  ];
  public status = [
    { value: undefined, name: 'All' },
    { value: "Backlog", name: 'Backlog' },
    { value: "InProgress", name: 'InProgress' },
    { value: "Complete", name: 'Completed' }
  ];
  public selectedSource: any;
  public selectedStatus: any;
  public dateValueTo: any;
  public dateValueFrom: any;
  public selectedcontractopportunitylist: any;
  public filterApply = false;
  public actualSavingValue: any = [];
  public maxActualSaving: any;
  public getActualSaving: any = 0;
  public changeValue: any = 0;
  public urlEndtoEnd: any;

  constructor(public ajWebService: AJWebService, private resolver: ComponentFactoryResolver, private spinner: NgxSpinnerService, private spinner2: NgxSpinnerService, private http: HttpClient, private appConfig: AppConfigService) {
    // this.AOFRagStatusClass = "ragRed";
  }


  ngOnInit(): void {

    this.onClearActualSavingClick();
    this.environmentDetails = this.appConfig.environmentSettings;
    this.displayNavbar = true;
    this.displayNavbarIconsWithText = true;

    this.grouping = true;
    this.showSummary = true;
    //this.GetAOFAssessments();
    //this.GetOpportunityList(undefined);
    this.totalSavingsValueForNullAttribute = 0;
    this.ajWebService.updateScopeSelector.subscribe(scopeSelectorData => {
      this.scopedata = scopeSelectorData;
      this.GetOpportunityList(this.ajWebService.LoadOpportunityRequest);
      if (this.scopedata != undefined)
        if (this.scopedata.cmsOrWS == "cms")
          this.getheatMapData(this.endToEndUId, this.scopedata.cmsOrWSUid, null);
        else if (this.scopedata.cmsOrWS == "ws" && (this.scopedata.wsUid != null || this.scopedata.wsUid != ""))
          this.getheatMapData(this.endToEndUId, null, this.scopedata.wsUid);
        else
          this.getheatMapData(this.endToEndUId, null, this.scopedata.wsUid);
      else
        this.getheatMapData(this.endToEndUId, null, null);
    }, error => {
      this.ajWebService.showErrorMessage(error);
    })
    this.ajWebService.change.subscribe(LoadOpportunityRequest => {
      this.LoadOpportunityRequest = LoadOpportunityRequest;
      if (this.ajWebService.tabCode == "MW") {
        //this.GetNotificationsList();
        this.GetOpportunityList(LoadOpportunityRequest);
        //this.GetAOFAssessments();
      }
    });

    this.endToEndUId = sessionStorage.getItem('E2E');
    let pattern = /[^a-z\d]/i;
    if (pattern.test(this.endToEndUId) == true) {
      this.urlEndtoEnd = this.endToEndUId;
    }

    console.log(this.endToEndUId + " ==> " + pattern.test(this.endToEndUId));
    this.getheatMapData(this.endToEndUId, null, null);
    this.ajWebService.onClear.subscribe(data => {
      this.onClearClick();
    },
      error => {
        console.log(error);
      })
  }

  getSetcmsFilter() {
    this.wsFilter = [{ "Workstream": "All" }];

    for (var i = 0; i < this.ajWebService.cmsData.length; i++) {
      if (this.ajWebService.cmsData[i].CMSUId == this.selectedcmsFilter) {

        // this.wsFilter = [{ "Workstream": "Select" }];

        this.wsFilter = this.ajWebService.cmsData[i].Workstreams;

        if (this.ajWebService.cmsData[i].CMSLevelTwo != '') {

          for (var j = 0; j < this.ajWebService.cmsData[i].CMSLevelTwo.length; j++) {
            this.wsFilter = this.wsFilter.concat(this.ajWebService.cmsData[i].CMSLevelTwo[j].Workstreams);

            if (this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree != '') {

              for (var k = 0; k < this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree.length; k++) {
                this.wsFilter = this.wsFilter.concat(this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree[k].Workstreams);

                if (this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree[k].CMSLevelFour != '') {

                  for (var l = 0; l < this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree[k].CMSLevelFour.length; l++) {
                    this.wsFilter = this.wsFilter.concat(this.ajWebService.cmsData[i].CMSLevelTwo[j].CMSLevelThree[k].CMSLevelFour[l].Workstreams);
                  }
                }

              }

            }

          }
        }

      }
    }

    //console.log(this.selectedcmsFilter);
    this.selectedcmsFlt = this.wsFilter[0]["WorkstreamUId"];
    //console.log(this.selectedcmsFlt);


  }
  datePopupClick() {
    event.stopPropagation();
  }
  onClearClick() {
    this.selectedStatus = undefined;
    this.selectedSource = undefined;
    this.selectedcmsFilter = undefined;
    this.wsFilter = [{ "Workstream": "All" }];
    this.dateValueFrom = undefined;
    this.dateValueTo = undefined;
    this.getActualSaving = 0;
    this.changeValue = 0.00;
  }

  onClearActualSavingClick() {
    this.getActualSaving = 0;
    this.changeValue = 0.00;
  }
  onCancleClick() {
    $(".dropdown-menu.dropdown-xl").removeClass('show');
  }

  ValueChangeTo(PlannedDate: string) {
    var getDate = PlannedDate["value"].getDate();
    var getMonth = PlannedDate["value"].getMonth() + 1;
    var date = getDate < 10 ? '0' + getDate : getDate;
    var month = getMonth < 10 ? '0' + getMonth : getMonth;
    this.dateValueTo = PlannedDate["value"].getFullYear() + "-" + month + "-" + date;
    event.stopPropagation();
  }
  ValueChangeFrom(PlannedDateFrom: string) {
    var getDate = PlannedDateFrom["value"].getDate();
    var getMonth = PlannedDateFrom["value"].getMonth() + 1;
    var date = getDate < 10 ? '0' + getDate : getDate;
    var month = getMonth < 10 ? '0' + getMonth : getMonth;
    this.dateValueFrom = PlannedDateFrom["value"].getFullYear() + "-" + date + "-" + month;
    event.stopPropagation();
  }

  valueChanged(e) {
    this.getActualSaving = Number(e);
    this.changeValue = this.getActualSaving != 0 ? (this.getActualSaving >= 1000 ? (this.getActualSaving / 1000).toFixed(2) + 'k' : this.getActualSaving.toFixed(2)) : '0.00';
  }
  onAdvClick() {
    $('.myw-aj-adv-fitler').removeClass('d-none');
    $('.adv-btn').addClass('d-none');
    $('.clear-btn').removeClass('d-none');
  }


  ApRedirect(BluePrintId: number, AppName: string, Type: string, DeepDiveId: number, CategoryId: number, SubCategoryId: number, CMSUid: number, WsUid: number) {
    sessionStorage.setItem('CategoryID', CategoryId.toString());
    sessionStorage.setItem('SubCategoryID', SubCategoryId.toString());
    localStorage.setItem('CategoryID', CategoryId.toString());
    localStorage.setItem('SubCategoryID', SubCategoryId.toString());

    var url = this.environmentDetails.apBluePrintURL + "?BPId=" + BluePrintId + "&E2EUID=" + this.urlEndtoEnd + "&CMS=" + CMSUid + "&WS=" + WsUid + "&AppName=" + AppName + "&DeepDiveId=" + DeepDiveId + "&CategoryId=" + CategoryId + "&SubCategoryId=" + SubCategoryId;
    window.open(url, "_blank");
  }

  APHome() {

    if (this.scopedata != undefined) {

      var cmsOrWSUid = null;
      var wsId = null;
      if (this.scopedata.cmsOrWS == "cms") {
        cmsOrWSUid = this.scopedata.cmsOrWSUid;
      }
      if (this.scopedata.cmsOrWS == "ws") {
        wsId = this.scopedata.wsUid != "" ? this.scopedata.wsUid : this.scopedata.cmsOrWSUid;
      }
      var url = this.environmentDetails.apTreasuryURL + "?E2EUID=" + this.urlEndtoEnd + "&CMS=" + cmsOrWSUid + "&WS=" + wsId;
      window.open(url, "_blank");
    }
    else
      window.open(this.environmentDetails.apTreasuryURL + "?E2EUID=" + this.urlEndtoEnd + "&CMS=null&WS=null", "_blank");
  }

  IOMRedirect() {


    if (this.scopedata != undefined) {

      var cmsOrWSUid = null;
      var wsId = null;
      if (this.scopedata.cmsOrWS == "cms") {
        cmsOrWSUid = this.scopedata.cmsOrWSUid;
      }
      if (this.scopedata.cmsOrWS == "ws") {
        wsId = this.scopedata.wsUid != "" ? this.scopedata.wsUid : this.scopedata.cmsOrWSUid;
      }
      var url = this.environmentDetails.aiaaHomeUrl + "?E2EUID=" + this.urlEndtoEnd + "&CMSUID=" + cmsOrWSUid + "&WSUID=" + wsId;
      window.open(url, "_blank");

      // if (this.scopedata != undefined) {
      //   var url = this.environmentDetails.aiaaHomeUrl + "?E2EUID=" + this.urlEndtoEnd + "&CMSUID=" + this.scopedata.cmsOrWSUid + "&WSUID=" + this.scopedata.wsUid;
      //   window.open(url, "_blank");
    }
    else
      window.open(this.environmentDetails.aiaaHomeUrl + "?E2EUID=" + this.urlEndtoEnd + "&CMSUID=&WSUID=", "_blank");
  }

  // AOFRedirect() {
  //   window.open(this.environmentDetails.aofHomeUrl + "?EndToEndUId=" + this.urlEndtoEnd + "&CMSUID=null&WSUID=null", "_blank");
  // }


  getheatMapData(endToEndId: string, cmsUId: string, wsUId: string) {
    endToEndId = sessionStorage.getItem('E2E');
    this.ajWebService.APtoAJHeatMap(endToEndId, cmsUId, wsUId).subscribe(data => {
      if (data != undefined) {
        let heatmapdata: any = JSON.parse(data.toString()).sort((a, b) => a.TicketCount > b.TicketCount ? -1 : 1);

        let response = [];
        let heatmapdataCount: number;
        if (heatmapdata.length > 20)
          heatmapdataCount = 20;// heatmapdata.length / 3; //commented for temporaryly
        //else if (heatmapdata.length > 50)
        //heatmapdataCount = heatmapdata.length / 2;
        else
          heatmapdataCount = heatmapdata.length;
        for (var i = 0; i < heatmapdataCount; i++)
          response.push({ 'variable': heatmapdata[i].SupportGroupName, 'value': heatmapdata[i].TicketCount, 'type': heatmapdata[i].ProblemStatementName });
        this.ajWebService.ticketDistributionData = response.sort((a, b) => a.value > b.value ? 1 : -1);
        this.ajWebService.ticketDistributionDataLoaded.emit("true");
        this.heatMapDataReceived = true;
      }
      this.hideSpinner(this.heatMapDataReceived, this.supportGroupDataReceived);
    },
      error => {
        this.spinner.hide();
        console.log(error)
      })
  }

  clearTicketDistChart() {
    if (document.getElementById("TktDistHeatMapPopUp") != undefined)
      document.getElementById("TktDistHeatMapPopUp").innerHTML = "";
  }

  viewROI(opportunityId: number) {
    this.opportunityId = opportunityId;
    this.ajWebService.displayROIGraphPopUp.emit(this.opportunityId.toString());
  }

  viewTicketVolume(opportunityId: number) {
    this.opportunityId = opportunityId;
    this.ajWebService.displayTicketVolumeGraphPopUp.emit(this.opportunityId.toString());
  }

  editTicketVolume(queueId: number, opportunityId: number, opportunityName: string, plannedStartDate: Date) {
    if (plannedStartDate != undefined) {
      this.displayPopup = "ticketVolume";
      this.ajWebService.lastActiveOpportunityId = this.opportunityId;
      this.opportunityId = opportunityId;
      this.opportunityName = opportunityName;
      this.benefitRealizationDate = plannedStartDate;
      this.assetsList = this.allOpportunityList.find(x => x.OpportunityId == opportunityId).Assets;
      this.getTicketVolume();
    } else
      this.ajWebService.showInfoMessage("Please enter benefit realization date");
  }


  viewSavings(opportunityId: number) {
    this.opportunityId = opportunityId;
    this.ajWebService.displaySavingsGraphPopUp.emit(this.opportunityId.toString());
  }

  viewComment(opportunityId: number, opportunityName: string) {
    this.opportunityName = opportunityName;
    this.opportunityId = opportunityId;
    this.ajWebService.displayComment.emit(this.opportunityId.toString());
  }

  editSavings(queueId: number, opportunityId: number, opportunityName: string, plannedStartDate: Date) {
    if (plannedStartDate != undefined) {
      this.displayPopup = "savingsAndFTE";
      this.opportunityId = opportunityId;
      this.opportunityName = opportunityName;
      this.benefitRealizationDate = plannedStartDate;
      this.getSavings();
    } else
      this.ajWebService.showInfoMessage("Please enter benefit realization date");
  }

  GetNotificationsList() {
    if (this.LoadOpportunityRequest != null && this.LoadOpportunityRequest != undefined) {
      this.ajWebService.FilteredCMSWSRequest = this.LoadOpportunityRequest;
      this.ajWebService.GetNotificationsList(this.ajWebService.FilteredCMSWSRequest).subscribe(data => {
        //console.log(data)
        this.alertsList = data["Notifications"];
      },
        error => {
          console.log(error)
        })
    }
  }

  GetOpportunityList(scopeSelectorData: any) {
    if (this.environmentDetails.MSALenabled == false)
      sessionStorage.setItem("E2E", "a60491aa-f072-4644-8f6b-faf4a9001a2a")

    // if (scopeSelectorData != undefined)
    //   this.ajWebService.FilteredCMSWSRequest = [{
    //     "UId": "23a198ba-89d7-4916-a198-e05c241572d2",
    //     "ParentUId": "0f25ca81-fa5e-4549-b653-5ae0cd9f3915",
    //     "EndToEndId": 40761,
    //     "EndToEndUId": sessionStorage.getItem('E2E'),
    //     "CMSId": scopeSelectorData.cmsOrWS == "cms" ? scopeSelectorData.cmsOrWSId : null,
    //     "CMSUId": scopeSelectorData.cmsOrWS == "cms" ? scopeSelectorData.cmsOrWSUid : null,
    //     "CMS": null,
    //     "WorkstreamId": scopeSelectorData.cmsOrWS == "ws" ? scopeSelectorData.cmsOrWSId : null,
    //     "WorkstreamUId": scopeSelectorData.cmsOrWS == "ws" ? scopeSelectorData.cmsOrWSUid : null,
    //     "GlobalId": 175785,
    //     "Workstream": "WS2",
    //     "WorkstreamTypeId": 2,
    //     "WorkstreamType": "Application / Application Group",
    //     "WorkstreamStatusId": 5,
    //     "WorkstreamStatus": "Service Delivery",
    //     "WorkstreamStartDate": null,
    //     "WorkstreamEndDate": null,
    //     "FactoryWorkstreamIndicator": "",
    //     "FactoryEndToEndId": 0,
    //     "FactoryEndToEndUId": "00000000-0000-0000-0000-000000000000",
    //     "ClosedOn": null,
    //     "ClosedWorkstreamAge": 0,
    //     "ClosedWorkstreamAgeLimit": 7,
    //     "ApplicableWorkstreamIndicator": "Y",
    //     "Type": scopeSelectorData.cmsOrWS == "cms" ? "CMS" : "Workstream"
    //   }];
    // else
    //   this.ajWebService.FilteredCMSWSRequest = [{
    //     "UId": null,
    //     "ParentUId": null,
    //     "EndToEndId": 40761,
    //     "EndToEndUId": sessionStorage.getItem('E2E'),
    //     "CMSId": 17870,
    //     "CMSUId": "23a198ba-89d7-4916-a198-e05c241572d2",
    //     "CMS": null,
    //     "WorkstreamId": 276595,
    //     "WorkstreamUId": "0f25ca81-fa5e-4549-b653-5ae0cd9f3915",
    //     "GlobalId": 175785,
    //     "Workstream": "WS2",
    //     "WorkstreamTypeId": 2,
    //     "WorkstreamType": "Application / Application Group",
    //     "WorkstreamStatusId": 5,
    //     "WorkstreamStatus": "Service Delivery",
    //     "WorkstreamStartDate": null,
    //     "WorkstreamEndDate": null,
    //     "FactoryWorkstreamIndicator": "",
    //     "FactoryEndToEndId": 0,
    //     "FactoryEndToEndUId": "00000000-0000-0000-0000-000000000000",
    //     "ClosedOn": null,
    //     "ClosedWorkstreamAge": 0,
    //     "ClosedWorkstreamAgeLimit": 7,
    //     "ApplicableWorkstreamIndicator": "Y",
    //     "Type": null
    //   }];

    this.showSpinner();

    let ssd = scopeSelectorData;

    this.ajWebService.GetOpportunityList(scopeSelectorData).subscribe(data => {
      this.opportunityList = data;
      if (data != undefined) {
        {
          if (data[0] != null || data[0] != undefined)
            this.BPId = data[0].BluePrintId;
        }
      }
      this.populateData();
    },
      error => {
        console.log(error)
      })
  }

  populateData() {
    this.savedCostTotal = 0;
    this.savedEffortTotal = 0;
    let actualTotalSavings = 0;
    let totalSavedCostForCompletedOpportunities = 0;
    let totalSavedEffortForCompletedOpportunities = 0;

    this.backlogList = [];
    this.inProgressList = [];
    this.completedList = [];
    this.cancelledList = [];
    this.allOpportunityList = [];

    this.backlogassetList = [];
    this.inProgressassetList = [];
    this.completedassetList = [];

    if (this.opportunityList != null || this.opportunityList != undefined) {
      for (var i = 0; i < this.opportunityList.length; i++)
        if (this.opportunityList[i] != undefined)
          if (this.filterApply == false) {
            for (var j = 0; j < this.opportunityList[i].Opportunities.length; j++) {
              this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
              this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

              this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
              this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

              this.actualSavingValue.push(this.opportunityList[i].Opportunities[j]["actualSAvings"]);

              // adding opportunity all 4 non accordion (unqueued) lists
              if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                this.backlogList.push(this.opportunityList[i].Opportunities[j]);
              }

              else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
              }
              else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                this.completedList.push(this.opportunityList[i].Opportunities[j]);
                this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
              }
              else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

              this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
              this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

              this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);



              // 2 queue defects
              // for (var k = 0; k < this.opportunityList[i].Opportunities[j].SupportGroupId.length; k++) {
              //   this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupId[k] != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId[k] : -10;

              //   this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName[k] != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName[k] : 'Others';

              //   this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
              // }


            }




            this.maxActualSaving = Math.max.apply(Math, this.actualSavingValue);

          } else {
            for (var j = 0; j < this.opportunityList[i].Opportunities.length; j++) {
              if ((this.selectedcmsFilter != undefined && this.selectedcmsFilter != "undefined") && (this.selectedcmsFlt == this.opportunityList[i].Opportunities[j].wsUId) || (this.selectedcmsFilter == this.opportunityList[i].Opportunities[j].cmsUID)) {
                if ((this.selectedSource == undefined || this.selectedSource == "undefined") && (this.selectedStatus == this.opportunityList[i].Opportunities[j].Status)) {
                  if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  }
                } else if (this.selectedSource == this.opportunityList[i].Opportunities[j].AppName) {
                  if (this.selectedStatus == this.opportunityList[i].Opportunities[j].Status) {
                    if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    }
                  } else if (this.opportunityList[i].Opportunities[j].PlannedStartDate >= this.dateValueFrom && this.opportunityList[i].Opportunities[j].PlannedStartDate <= this.dateValueTo) {
                    if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    }
                  } else if ((this.selectedStatus == undefined || this.selectedStatus == "undefined") && (this.dateValueFrom == undefined || this.dateValueFrom == "undefined" || this.dateValueFrom == "") && (this.dateValueTo == undefined || this.dateValueTo == "undefined" || this.dateValueTo == "")) {
                    if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                      this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                      this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                      this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                      // adding opportunity all 4 non accordion (unqueued) lists
                      if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                      }

                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                        this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                        totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                        totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                        actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                        this.completedList.push(this.opportunityList[i].Opportunities[j]);
                        this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                        this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      }
                      else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                        this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                      this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                      this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                      this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                    }
                  }

                } else if ((this.selectedSource == undefined || this.selectedSource == "undefined") && (this.opportunityList[i].Opportunities[j].PlannedStartDate >= this.dateValueFrom && this.opportunityList[i].Opportunities[j].PlannedStartDate <= this.dateValueTo)) {
                  if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  }
                } else if ((this.selectedSource == undefined || this.selectedSource == "undefined") && (this.selectedStatus == undefined || this.selectedStatus == "undefined") && (this.dateValueFrom == undefined || this.dateValueFrom == "undefined" || this.dateValueFrom == "") && (this.dateValueTo == undefined || this.dateValueTo == "undefined" || this.dateValueTo == "")) {
                  if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSAvings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSAvings"] <= this.getActualSaving)) {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  }
                }

                // 2 queue defects
                // for (var k = 0; k < this.opportunityList[i].Opportunities[j].SupportGroupId.length; k++) {
                //   this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupId[k] != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId[k] : -10;

                //   this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName[k] != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName[k] : 'Others';

                //   this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                // }
              } else if ((this.selectedcmsFilter == undefined || this.selectedcmsFilter == "undefined") && (this.selectedSource == undefined || this.selectedSource == "undefined") && (this.selectedStatus == undefined || this.selectedStatus == "undefined") && (this.opportunityList[i].Opportunities[j].PlannedStartDate >= this.dateValueFrom && this.opportunityList[i].Opportunities[j].PlannedStartDate <= this.dateValueTo)) {
                if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                }
              } else if ((this.selectedSource == undefined || this.selectedSource == "undefined") && (this.selectedcmsFilter == undefined || this.selectedcmsFilter == "undefined") && (this.selectedStatus == this.opportunityList[i].Opportunities[j].Status)) {
                if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                }
              } else if ((this.selectedcmsFilter == undefined || this.selectedcmsFilter == "undefined") && (this.selectedSource == this.opportunityList[i].Opportunities[j].AppName)) {
                if (this.selectedStatus == this.opportunityList[i].Opportunities[j].Status) {
                  if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  }
                } else if (this.opportunityList[i].Opportunities[j].PlannedStartDate >= this.dateValueFrom && this.opportunityList[i].Opportunities[j].PlannedStartDate <= this.dateValueTo) {
                  if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSavings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSavings"] <= this.getActualSaving)) {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                    this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                    this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                    this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                    // adding opportunity all 4 non accordion (unqueued) lists
                    if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                    }

                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                      this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                      totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                      totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                      actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                      this.completedList.push(this.opportunityList[i].Opportunities[j]);
                      this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                      this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    }
                    else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                      this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                    this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                    this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                    this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                  }
                } else if ((this.selectedStatus == undefined || this.selectedStatus == "undefined") && (this.dateValueFrom == undefined || this.dateValueFrom == "undefined" || this.dateValueFrom == "") && (this.dateValueTo == undefined || this.dateValueTo == "undefined" || this.dateValueTo == "")) {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';
                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                }

                // 2 queue defects
                // for (var k = 0; k < this.opportunityList[i].Opportunities[j].SupportGroupId.length; k++) {
                //   this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupId[k] != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId[k] : -10;

                //   this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName[k] != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName[k] : 'Others';

                //   this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                // }
              } else if ((this.selectedcmsFilter == undefined || this.selectedcmsFilter == "undefined") && (this.selectedSource == undefined || this.selectedSource == "undefined") && (this.selectedStatus == undefined || this.selectedStatus == "undefined") && (this.dateValueFrom == undefined || this.dateValueFrom == "undefined" || this.dateValueFrom == "") && (this.dateValueTo == undefined || this.dateValueTo == "undefined" || this.dateValueTo == "")) {

                if (this.getActualSaving != 0 && (this.opportunityList[i].Opportunities[j]["actualSAvings"] >= 0 && this.opportunityList[i].Opportunities[j]["actualSAvings"] <= this.getActualSaving)) {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                } else if (this.getActualSaving == 0 || this.getActualSaving == undefined || this.getActualSaving == "undefined") {
                  this.opportunityList[i].Opportunities[j]["currentPlan"] = Math.round((this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date()));
                  this.opportunityList[i].Opportunities[j]["currentPlan"] != 0 ? this.opportunityList[i].Opportunities[j]["currentPlan"] = this.opportunityList[i].Opportunities[j]["currentPlan"] + '.00' : '';

                  this.opportunityList[i].Opportunities[j]["actualSavings"] = this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                  this.opportunityList[i].Opportunities[j]["actualSAvings"] != 0 ? this.opportunityList[i].Opportunities[j]["actualSAvings"] = this.opportunityList[i].Opportunities[j]["actualSAvings"] + '' : '';

                  // adding opportunity all 4 non accordion (unqueued) lists
                  if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("backlog") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.backlogList.push(this.opportunityList[i].Opportunities[j]);
                  }

                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("inprogress") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    this.inProgressList.push(this.opportunityList[i].Opportunities[j]);

                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("complete") >= 0) {
                    this.opportunityList[i].Opportunities[j]["BluePrintId"] = this.opportunityList[i].BluePrintId;
                    totalSavedCostForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedCost != undefined ? this.opportunityList[i].Opportunities[j].SavedCost : 0;
                    totalSavedEffortForCompletedOpportunities += this.opportunityList[i].Opportunities[j].SavedEffort != undefined ? this.opportunityList[i].Opportunities[j].SavedEffort : 0;

                    actualTotalSavings += this.opportunityList[i].Opportunities[j].AttributeValue != undefined ? this.opportunityList[i].Opportunities[j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : 0;
                    this.completedList.push(this.opportunityList[i].Opportunities[j]);
                    this.savedCostTotal += (this.opportunityList[i].Opportunities[j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                    this.savedEffortTotal += (this.opportunityList[i].Opportunities[j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityList[i].Opportunities[j].PlannedStartDate), new Date());
                  }
                  else if (this.opportunityList[i].Opportunities[j].Status.toString().toLowerCase().indexOf("cancel") >= 0)
                    this.cancelledList.push(this.opportunityList[i].Opportunities[j]);

                  this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId : -10;
                  this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName : 'Others';

                  this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                }


                // 2 queue defects
                // for (var k = 0; k < this.opportunityList[i].Opportunities[j].SupportGroupId.length; k++) {
                //   this.opportunityList[i].Opportunities[j].queueId = this.opportunityList[i].Opportunities[j].SupportGroupId[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupId[k] != 0 ? this.opportunityList[i].Opportunities[j].SupportGroupId[k] : -10;

                //   this.opportunityList[i].Opportunities[j].queueName = this.opportunityList[i].Opportunities[j].SupportGroupName[k] != undefined && this.opportunityList[i].Opportunities[j].SupportGroupName[k] != '' ? this.opportunityList[i].Opportunities[j].SupportGroupName[k] : 'Others';

                //   this.allOpportunityList.push(this.opportunityList[i].Opportunities[j]);
                // }

              }
            }
          }

      console.log(this.allOpportunityList);

    }

    this.savedCostTotal = Math.round(this.savedCostTotal);
    this.savedEffortTotal = Math.round(this.savedEffortTotal);

    let costPerHour = totalSavedEffortForCompletedOpportunities != 0 ? totalSavedCostForCompletedOpportunities / totalSavedEffortForCompletedOpportunities : 0;
    this.ajWebService.plannedTotalEfforts = Math.round(this.savedEffortTotal);
    this.ajWebService.costPerHour = costPerHour;
    this.ajWebService.actualTotalSavings = Math.round(actualTotalSavings);
    this.ajWebService.plannedTotalSavings = Math.round(this.savedCostTotal);
    let obj = this;
    setTimeout(function () {
      if (!obj.toggleChart) {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAutomationOpportunityData.emit("true");
        obj.ajWebService.opportunityQueuesLoaded.emit("true");
        obj.ajWebService.ticketDistributionDataLoaded.emit("true");
      }
      else {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAssetProvisioningData.emit("true");
        obj.ajWebService.opportunityQueuesLoaded.emit("true");
        obj.ajWebService.ticketDistributionDataLoaded.emit("true");
      }
    }, 1000)

    // Forming opportunity queues for displaying accordion data
    this.queueIdArray = [];
    for (var i = 0; i < this.allOpportunityList.length; i++)
      if (this.queueIdArray.indexOf(this.allOpportunityList[i].queueId) < 0)
        this.queueIdArray.push(this.allOpportunityList[i].queueId);

    this.noOfOQdetailsFetched = 0;
    this.opportunityQueues = [];
    this.copyOpportunityQueues = [];
    this.opportunityQueuesBackup = [];
    this.opportunityQueuesFilteredData = [];

    for (var i = 0; i < this.queueIdArray.length; i++) {
      let singleBackLogList: any[] = [];
      let singleInProgressList: any[] = [];
      let singleCompletedList: any[] = [];
      let singleCancelledList: any[] = [];

      for (var j = 0; j < this.allOpportunityList.length; j++)
        if (this.allOpportunityList[j].queueId == this.queueIdArray[i])
          if (this.allOpportunityList[j].Status == "Backlog")
            singleBackLogList.push(this.allOpportunityList[j]);
          else if (this.allOpportunityList[j].Status == "InProgress")
            singleInProgressList.push(this.allOpportunityList[j]);
          else if (this.allOpportunityList[j].Status == "Complete")
            singleCompletedList.push(this.allOpportunityList[j]);
          else
            singleCancelledList.push(this.allOpportunityList[j]);


      this.opportunityQueues.push({
        'Backlog': singleBackLogList, 'InProgress': singleInProgressList, 'Complete': singleCompletedList,
        'Cancelled': singleCancelledList, 'queueId': this.queueIdArray[i], 'queueName': singleBackLogList.length > 0 ? singleBackLogList[0].queueName : (singleInProgressList.length > 0 ? singleInProgressList[0].queueName : (singleCompletedList.length > 0 ? singleCompletedList[0].queueName : singleCancelledList[0].queueName))
      })
    }

    //asset list data
    for (var i = 0; i < this.allOpportunityList.length; i++) {
      if (this.allOpportunityList[i] != undefined)
        for (var j = 0; j < this.allOpportunityList[i].Assets.length; j++)
          if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("backlog") >= 0) {
            this.backlogassetList.push(this.allOpportunityList[i].Assets[j]);
          }
          else if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("inprogress") >= 0) {
            this.inProgressassetList.push(this.allOpportunityList[i].Assets[j]);
          }
          else if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("complete") >= 0) {
            this.completedassetList.push(this.allOpportunityList[i].Assets[j]);
          }
    }

    this.backlogListBackup = this.backlogList;
    this.inProgressListBackup = this.inProgressList;
    this.completedListBackup = this.completedList;
    this.cancelledListBackup = this.cancelledList;

    this.opportunityQueuesFilteredData = JSON.parse(JSON.stringify(this.opportunityQueues));
    this.opportunityQueuesBackup = JSON.parse(JSON.stringify(this.opportunityQueuesFilteredData));

    this.ajWebService.backlogassetList = this.backlogassetList;
    this.ajWebService.inProgressassetList = this.inProgressassetList;
    this.ajWebService.completedassetList = this.completedassetList;


    this.ajWebService.backlogList = this.backlogList;
    this.ajWebService.inProgressList = this.inProgressList;
    this.ajWebService.completedList = this.completedList;
    this.ajWebService.cancelledList = this.cancelledList;

    var list = this.opportunityQueues;

    setTimeout(function () {
      if (list != undefined) {
        for (var i = 0; i < list.length; i++) {
          var id = list[i]["queueId"];
          $('#expandsearch' + id).removeClass('ml-auto myw-accordion-toggle');
          $('#expandsearch' + id).addClass('ml-auto myw-accordion-toggle collapsed');
        }
      }
      obj.spinner.hide();
    }, 100)

    this.ajWebService.showAutomationOpportunitiesData.emit("true");

    for (var i = 0; i < this.opportunityQueues.length; i++) {
      this.groupedOQSortOrders[i] = [];
      this.groupedOQSortOrders[i]["queueId"] = this.opportunityQueues[i]["queueId"];
      this.groupedOQSortOrders[i]["Backlog"] = false;
      this.groupedOQSortOrders[i]["InProgress"] = false;
      this.groupedOQSortOrders[i]["Complete"] = false;
      this.groupedOQSortOrders[i]["Cancelled"] = false;
    }

    this.reCalculateTotalSavingsAndEfforts();
    this.copyOpportunityQueues = this.opportunityQueues;
    this.ajWebService.opportunityQueues = this.opportunityQueues;
    // this.ajWebService.opportunityQueuesLoaded.emit("true");
    this.supportGroupDataReceived = true;
    this.spinner.hide();
  }

  //Asset provisioning graph dynamic data passing when opportunity transfer
  recalculateAssetList() {
    this.backlogassetList = [];
    this.inProgressassetList = [];
    this.completedassetList = [];

    for (var i = 0; i < this.allOpportunityList.length; i++) {
      if (this.allOpportunityList[i] != undefined)
        for (var j = 0; j < this.allOpportunityList[i].Assets.length; j++)
          if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("backlog") >= 0) {
            this.backlogassetList.push(this.allOpportunityList[i].Assets[j]);
          }
          else if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("inprogress") >= 0) {
            this.inProgressassetList.push(this.allOpportunityList[i].Assets[j]);
          }
          else if (this.allOpportunityList[i].Assets[j].OrderStatus.toString().toLowerCase().indexOf("complete") >= 0) {
            this.completedassetList.push(this.allOpportunityList[i].Assets[j]);
          }
    }
    this.ajWebService.backlogassetList = this.backlogassetList;
    this.ajWebService.inProgressassetList = this.inProgressassetList;
    this.ajWebService.completedassetList = this.completedassetList;
    var obj = this;
    setTimeout(function () {
      if (!obj.toggleChart) {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAutomationOpportunityData.emit("true");
      }
      else {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAssetProvisioningData.emit("true");
      }
    }, 1000)

  }

  changeClass() {
    var list = this.opportunityQueues;

    setTimeout(function () {
      if (list != undefined) {
        for (var i = 0; i < list.length; i++) {
          var id = list[i]["queueId"];

          $('#expandsearch' + id).removeClass('ml-auto myw-accordion-toggle');
          $('#expandsearch' + id).addClass('ml-auto myw-accordion-toggle collapsed');
        }
      }
    }, 10)
  }

  // ChangePinClass()
  // {

  //   if($('#nav-pin').hasClass('collapsed'))
  //   {
  //     $('#nav-pin').removeClass('collapsed');
  //   }
  //   else
  //   {
  //     $('#nav-pin').addClass('collapsed');
  //   }
  // }

  monthDiff(date, currentDate) {
    var months
    months = (currentDate.getFullYear() - date.getFullYear()) * 12;
    months -= date.getMonth();
    months += currentDate.getMonth();
    return months <= 0 ? 0 : months;
  }

  dragAndDropGroupedOpportunity(event: CdkDragDrop<string[]>) {
    let sourceContainerId = event.previousContainer.id;
    let destinationContainerId = event.container.id;
    let sourceOpportunityListData: any[] = event.previousContainer.data;
    let destinationOpportunityListData: any[] = event.container.data;
    let transferredOpportunityId = event.item.element.nativeElement.id;
    let transferredOpportunity = sourceOpportunityListData.find(x => x.OpportunityId == transferredOpportunityId);

    if (sourceContainerId !== destinationContainerId) {
      if (sourceContainerId.indexOf("groupedBacklogListContainer") >= 0) {
        if (destinationContainerId.indexOf("groupedInProgressListContainer") >= 0) {
          transferredOpportunity["Status"] = "InProgress";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.backlogList, this.inProgressList, "backlog", "inprogress", transferredOpportunity["Status"], "ungrouped");
        } else if (destinationContainerId.indexOf("groupedCancelledListContainer") >= 0) {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.backlogList, this.cancelledList, "backlog", "cancelled", transferredOpportunity["Status"], "ungrouped");
        }
        else if (destinationContainerId.indexOf("groupedCompleteListContainer") >= 0)
          // if (transferredOpportunity["PlannedStartDate"] == null) {
          bootbox.alert({
            message: "Opportunity cannot be moved to Completed state without benefit realization date.Please move opportunity to inprogress state and select a date before moving it to completed section."
          }).find(".btn-primary").removeClass("btn").css({
            padding: "6px 12px",
            "border-radius": "0rem",
            border: "1px solid transparent"
          }).find(".modal-content").css({
            "border-radius": "0rem"
          });
        // }
        // else {
        //   this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, this.backlogList, this.completedList, "backlog", "complete", false, "ungrouped");
        // }

      } else if (sourceContainerId.indexOf("groupedInProgressListContainer") >= 0) {
        if (destinationContainerId.indexOf("groupedBacklogListContainer") >= 0) {
          transferredOpportunity["Status"] = "Backlog";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.inProgressList, this.backlogList, "inprogress", "backlog", transferredOpportunity["Status"], "ungrouped");
        } else if (destinationContainerId.indexOf("groupedCompleteListContainer") >= 0)
          if (transferredOpportunity["PlannedStartDate"] == null) {
            bootbox.alert({
              message: "Please select benefit realization date before moving an opportunity to completed state"
            }).find(".btn-primary").removeClass("btn").css({
              padding: "6px 12px",
              "border-radius": "0rem",
              border: "1px solid transparent"
            }).find(".modal-content").css({
              "border-radius": "0rem"
            });
          }
          else {
            this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, this.inProgressList, this.completedList, "inprogress", "complete", true, "ungrouped");
          }
        else {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.inProgressList, this.cancelledList, "inprogress", "cancelled", transferredOpportunity["Status"], "ungrouped");
        }
      } else if (sourceContainerId.indexOf("groupedCompleteListContainer") >= 0) {
        if (destinationContainerId.indexOf("groupedBacklogListContainer") >= 0) {
          bootbox.alert({
            message: "Opportunity cannot be moved to Completed state to Backlog state."
          }).find(".btn-primary").removeClass("btn").css({
            padding: "6px 12px",
            "border-radius": "0rem",
            border: "1px solid transparent"
          }).find(".modal-content").css({
            "border-radius": "0rem"
          });
        } else if (destinationContainerId.indexOf("groupedInProgressListContainer") >= 0) {
          bootbox.alert({
            message: "You cannot move an opportunity from completed to in progress state."
          }).find(".btn-primary").removeClass("btn").css({
            padding: "6px 12px",
            "border-radius": "0rem",
            border: "1px solid transparent"
          }).find(".modal-content").css({
            "border-radius": "0rem"
          });
        } else {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.completedList, this.cancelledList, "complete", "cancelled", transferredOpportunity["Status"], "ungrouped");
        }
      } else {
        if (destinationContainerId.indexOf("groupedBacklogListContainer") >= 0 || destinationContainerId.indexOf("groupedInProgressListContainer") >= 0) {
          if (destinationContainerId.indexOf("groupedBacklogListContainer") >= 0) {
            transferredOpportunity["Status"] = "Backlog";
            transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
            this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.cancelledList, this.backlogList, "cancelled", "backlog", transferredOpportunity["Status"], "ungrouped");
          }
          else {
            transferredOpportunity["Status"] = "InProgress";
            transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
            this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, this.cancelledList, this.inProgressList, "cancelled", "inprogress", transferredOpportunity["Status"], "ungrouped");
          }
        } else
          this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, this.cancelledList, this.completedList, "cancelled", "complete", false, "ungrouped");
      }
    }

    this.ajWebService.UpdateAutomationJourney(sessionStorage.getItem('E2E'), this.ajWebService.enterpriseId, transferredOpportunityId, true).subscribe(data => {
      console.log(data);
    },
      error => {
        console.log(error);
      })
    this.recalculateAssetList();
  }

  dragAndDropUnGroupedOpportunity(event: CdkDragDrop<string[]>) {
    let sourceContainerId = event.previousContainer.id;
    let destinationContainerId = event.container.id;
    let sourceOpportunityListData: any[] = event.previousContainer.data;
    let destinationOpportunityListData: any[] = event.container.data;
    let transferredOpportunityId = event.item.element.nativeElement.id;
    let transferredOpportunity = sourceOpportunityListData.find(x => x.OpportunityId == transferredOpportunityId);

    if (sourceContainerId !== destinationContainerId) {
      if (sourceContainerId == "backlogListContainer") {
        if (destinationContainerId == "inProgressListContainer") {
          transferredOpportunity["Status"] = "InProgress";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "backlog", "inprogress", transferredOpportunity["Status"], "grouped");
        } else if (destinationContainerId == "cancelledListContainer") {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "backlog", "cancelled", transferredOpportunity["Status"], "grouped");
        }
        else if (destinationContainerId == "completedListContainer")
          this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, sourceOpportunityListData, destinationOpportunityListData, "backlog", "complete", false, "grouped");
      } else if (sourceContainerId == "inProgressListContainer") {
        if (destinationContainerId == "backlogListContainer") {
          transferredOpportunity["Status"] = "Backlog";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "inprogress", "backlog", transferredOpportunity["Status"], "grouped");
        } else if (destinationContainerId == "completedListContainer")
          this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, sourceOpportunityListData, destinationOpportunityListData, "inprogress", "complete", true, "grouped");
        else {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "inprogress", "cancelled", transferredOpportunity["Status"], "grouped");
        }
      } else if (sourceContainerId == "completedListContainer") {
        if (destinationContainerId == "backlogListContainer") {
          transferredOpportunity["Status"] = "Backlog";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "complete", "backlog", transferredOpportunity["Status"], "grouped");
        } else if (destinationContainerId == "inProgressListContainer") {
          bootbox.alert({
            message: "You cannot move an opportunity from completed to in progress state."
          }).find(".btn-primary").removeClass("btn").css({
            padding: "6px 12px",
            "border-radius": "0rem",
            border: "1px solid transparent"
          }).find(".modal-content").css({
            "border-radius": "0rem"
          });
        } else {
          transferredOpportunity["Status"] = "Cancelled";
          transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
          this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "complete", "cancelled", transferredOpportunity["Status"], "grouped");
        }
      } else {
        if (destinationContainerId == "backlogListContainer" || destinationContainerId == "inProgressListContainer") {
          if (destinationContainerId == "backlogListContainer") {
            transferredOpportunity["Status"] = "Backlog";
            transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
            this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "cancelled", "backlog", transferredOpportunity["Status"], "grouped");
          }
          else {
            transferredOpportunity["Status"] = "InProgress";
            transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
            this.updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityListData, destinationOpportunityListData, "cancelled", "inprogress", transferredOpportunity["Status"], "grouped");
          }
        } else
          this.checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, sourceOpportunityListData, destinationOpportunityListData, "cancelled", "complete", false, "grouped");
      }
    }

    this.ajWebService.UpdateAutomationJourney(sessionStorage.getItem('E2E'), this.ajWebService.enterpriseId, transferredOpportunityId, true).subscribe(data => {
      console.log(data);
    },
      error => {
        console.log(error);
      })
  }

  checkForCompletedAssets(event, transferredOpportunity, sourceOpportunityListData, destinationOpportunityListData, sourceData, destinationData, sourceListOpportunityStatus, targetListOpportunityStatus, inProgressToCompleted, updateIn: string) {
    let allAssetsCompleted: boolean = true;
    let currObj = this;
    for (var i = 0; i < transferredOpportunity["Assets"].length; i++)
      if (transferredOpportunity["Assets"][i]["OrderStatus"] != "Complete")
        allAssetsCompleted = false;
    if (!allAssetsCompleted)
      bootbox.confirm({
        message: "Do you want to mark all the assets as completed?",
        callback: function (result) {
          if (result) {
            for (var i = 0; i < transferredOpportunity["Assets"].length; i++)
              transferredOpportunity["Assets"][i]["OrderStatus"] = "Complete";
            transferredOpportunity["Status"] = "Complete";
            transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
            currObj.updateOpportunityStatusAfterTransfer(transferredOpportunity["OpportunityId"], sourceData, destinationData, sourceListOpportunityStatus, targetListOpportunityStatus, transferredOpportunity["Status"], updateIn);
          }
        }
      }).find(".btn-primary").removeClass("btn").css({
        padding: "6px 12px",
        "border-radius": "0rem",
        border: "1px solid transparent"
      }).find(".modal-content").css({
        "border-radius": "0rem"
      });
    else {
      transferredOpportunity["Status"] = "Complete";
      transferArrayItem(sourceOpportunityListData, destinationOpportunityListData, event.previousIndex, event.currentIndex);
      this.updateOpportunityStatusAfterTransfer(transferredOpportunity["OpportunityId"], sourceData, destinationData, sourceListOpportunityStatus, targetListOpportunityStatus, transferredOpportunity["Status"], updateIn);
      if (inProgressToCompleted)
        bootbox.alert({
          message: "All assets completed."
        }).find(".btn-primary").removeClass("btn").css({
          padding: "6px 12px",
          "border-radius": "0rem",
          border: "1px solid transparent"
        }).find(".modal-content").css({
          "border-radius": "0rem"
        });
    }
  }

  updateOpportunityStatusAfterTransfer(transferredOpportunityId, sourceOpportunityList, targetOpportunityList, sourceListOpportunityStatus, targetListOpportunityStatus, transferredOpportunityStatus, updateIn: string) {
    this.ajWebService.UpdateOppertunityStatus(transferredOpportunityId, transferredOpportunityStatus).subscribe(data => {
      this.transferOpportunityinTargetList(transferredOpportunityId, sourceOpportunityList, targetOpportunityList, sourceListOpportunityStatus, targetListOpportunityStatus, updateIn);
      this.copyOpportunityQueues = this.opportunityQueues;
    },
      error => {
        console.log(error)
      })
  }

  transferOpportunityinTargetList(transferredOpportunityId, sourceOpportunityList, targetOpportunityList, sourceListOpportunityStatus, targetListOpportunityStatus, updateIn: string) {
    if (updateIn == "ungrouped") {
      targetOpportunityList.push(sourceOpportunityList.find(x => x.OpportunityId == transferredOpportunityId));
      sourceOpportunityList = sourceOpportunityList.filter(x => x.OpportunityId != transferredOpportunityId);

      if (sourceListOpportunityStatus == "backlog")
        this.backlogList = sourceOpportunityList;
      else if (sourceListOpportunityStatus == "inprogress")
        this.inProgressList = sourceOpportunityList;
      else if (sourceListOpportunityStatus == "complete")
        this.completedList = sourceOpportunityList;
      else
        this.cancelledList = sourceOpportunityList;

      if (targetListOpportunityStatus == "backlog")
        this.backlogList = targetOpportunityList;
      else if (targetListOpportunityStatus == "inprogress")
        this.inProgressList = targetOpportunityList;
      else if (targetListOpportunityStatus == "complete")
        this.completedList = targetOpportunityList;
      else
        this.cancelledList = targetOpportunityList;

      this.ajWebService.backlogList = this.backlogList;
      this.ajWebService.inProgressList = this.inProgressList;
      this.ajWebService.completedList = this.completedList;
      this.ajWebService.cancelledList = this.cancelledList;

      this.backlogListBackup = this.backlogList;
      this.inProgressListBackup = this.inProgressList;
      this.completedListBackup = this.completedList;
      this.cancelledListBackup = this.cancelledList;

      this.ajWebService.opportunityQueues = this.opportunityQueues;

      this.ajWebService.opportunityQueuesLoaded.emit("true");
      this.reCalculateTotalSavingsAndEfforts();
    } else {

    }
  }

  reCalculateTotalSavingsAndEfforts() {
    this.savedCostTotal = 0;
    this.savedEffortTotal = 0;
    let actualTotalSavings = 0;
    let plannedSavingsOfQueue;
    let actualSavingsOfQueue;

    let totalSavedCostForCompletedOpportunities = 0;
    let totalSavedEffortForCompletedOpportunities = 0;

    for (var i = 0; i < this.opportunityQueues.length; i++) {
      plannedSavingsOfQueue = 0;
      actualSavingsOfQueue = 0;

      if (this.opportunityQueues[i]["Backlog"] != undefined && this.opportunityQueues[i]["Backlog"].length > 0)
        for (var j = 0; j < this.opportunityQueues[i]["Backlog"].length; j++)
          if (this.opportunityQueues[i]["Backlog"][j].PlannedStartDate != undefined) {
            this.opportunityQueues[i]["Backlog"][j]["currentPlan"] = Math.round((this.opportunityQueues[i]["Backlog"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Backlog"][j].PlannedStartDate), new Date()));
            // plannedSavingsOfQueue += (this.opportunityQueues[i]["Backlog"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Backlog"][j].PlannedStartDate), new Date());
          }

      if (this.opportunityQueues[i]["InProgress"] != undefined && this.opportunityQueues[i]["InProgress"].length > 0)
        for (var j = 0; j < this.opportunityQueues[i]["InProgress"].length; j++)
          if (this.opportunityQueues[i]["InProgress"][j].PlannedStartDate != null) {
            this.opportunityQueues[i]["InProgress"][j]["currentPlan"] = Math.round((this.opportunityQueues[i]["InProgress"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["InProgress"][j].PlannedStartDate), new Date()));
            plannedSavingsOfQueue += (this.opportunityQueues[i]["InProgress"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["InProgress"][j].PlannedStartDate), new Date());
            this.savedCostTotal += (this.opportunityQueues[i]["InProgress"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["InProgress"][j].PlannedStartDate), new Date());
          }

      if (this.opportunityQueues[i]["Complete"] != undefined && this.opportunityQueues[i]["Complete"].length > 0)
        for (var j = 0; j < this.opportunityQueues[i]["Complete"].length; j++) {
          this.opportunityQueues[i]["Complete"][j]["currentPlan"] = Math.round((this.opportunityQueues[i]["Complete"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Complete"][j].PlannedStartDate), new Date()));

          plannedSavingsOfQueue += (this.opportunityQueues[i]["Complete"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Complete"][j].PlannedStartDate), new Date());

          this.opportunityQueues[i]["Complete"][j]["actualSavings"] = this.opportunityQueues[i]["Complete"][j].AttributeValue != undefined ? this.opportunityQueues[i]["Complete"][j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : this.totalSavingsValueForNullAttribute;

          actualSavingsOfQueue += this.opportunityQueues[i]["Complete"][j].AttributeValue != undefined ? this.opportunityQueues[i]["Complete"][j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : this.totalSavingsValueForNullAttribute;

          actualTotalSavings += this.opportunityQueues[i]["Complete"][j].AttributeValue != undefined ? this.opportunityQueues[i]["Complete"][j]["AttributeValue"]["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"].map(item => item.Value).reduce((prev, next) => prev + next) : this.totalSavingsValueForNullAttribute;

          this.savedCostTotal += (this.opportunityQueues[i]["Complete"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Complete"][j].PlannedStartDate), new Date());
          this.savedEffortTotal += (this.opportunityQueues[i]["Complete"][j].SavedEffort / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Complete"][j].PlannedStartDate), new Date());

          totalSavedCostForCompletedOpportunities += this.opportunityQueues[i]["Complete"][j].SavedCost != undefined ? +this.opportunityQueues[i]["Complete"][j].SavedCost : 0;
          totalSavedEffortForCompletedOpportunities += this.opportunityQueues[i]["Complete"][j].SavedEffort != undefined ? +this.opportunityQueues[i]["Complete"][j].SavedEffort : 0;
        }

      if (this.opportunityQueues[i]["Cancelled"] != undefined && this.opportunityQueues[i]["Cancelled"].length > 0)
        for (var j = 0; j < this.opportunityQueues[i]["Cancelled"].length; j++)
          if (this.opportunityQueues[i]["Cancelled"][j].PlannedStartDate != undefined) {
            this.opportunityQueues[i]["Cancelled"][j]["currentPlan"] = Math.round((this.opportunityQueues[i]["Cancelled"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Cancelled"][j].PlannedStartDate), new Date()));
            // plannedSavingsOfQueue += (this.opportunityQueues[i]["Cancelled"][j].SavedCost / 12) * this.monthDiff(new Date(this.opportunityQueues[i]["Cancelled"][j].PlannedStartDate), new Date());
          }

      plannedSavingsOfQueue = plannedSavingsOfQueue != 0 ? (plannedSavingsOfQueue >= 1000 ? (plannedSavingsOfQueue / 1000).toFixed(2) + 'k' : plannedSavingsOfQueue.toFixed(2)) : '0.00';
      this.opportunityQueues[i]["plannedSavingsOfQueue"] = plannedSavingsOfQueue;

      actualSavingsOfQueue = actualSavingsOfQueue != 0 ? (actualSavingsOfQueue >= 1000 ? (actualSavingsOfQueue / 1000).toFixed(2) + 'k' : actualSavingsOfQueue.toFixed(2)) : '0.00';
      this.opportunityQueues[i]["actualSavingsOfQueue"] = actualSavingsOfQueue;
    }

    this.savedCostTotal = Math.round(this.savedCostTotal);
    this.savedEffortTotal = Math.round(this.savedEffortTotal);

    let costPerHour = totalSavedEffortForCompletedOpportunities != 0 ? totalSavedCostForCompletedOpportunities / totalSavedEffortForCompletedOpportunities : 0;
    this.ajWebService.plannedTotalEfforts = Math.round(this.savedEffortTotal);
    this.ajWebService.costPerHour = costPerHour;
    this.ajWebService.actualTotalSavings = Math.round(actualTotalSavings);
    this.ajWebService.plannedTotalSavings = Math.round(this.savedCostTotal);
    this.recalculateAssetList();
    let obj = this;
    setTimeout(function () {
      if (!obj.toggleChart) {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAutomationOpportunityData.emit("true");
        obj.ajWebService.opportunityQueuesLoaded.emit("true");
        obj.ajWebService.ticketDistributionDataLoaded.emit("true");
      }
      else {
        obj.ajWebService.outcomesDataCalculationCompleted.emit("true");
        obj.ajWebService.loadAssetProvisioningData.emit("true");
        obj.ajWebService.opportunityQueuesLoaded.emit("true");
        obj.ajWebService.ticketDistributionDataLoaded.emit("true");
      }
    }, 1000)
  }

  changeAssetStatus(queueId: number, opportunityList: string, opportunityId: number, assetId: number, assetName: string, assetType: string, newStatus: string, PlannedStartDate: any) {
    if (PlannedStartDate != null) {
      if (opportunityList == "InProgress" && newStatus == "Complete") {
        let currentOpportunity: any;
        let sourceOpportunityList: any;
        let targetOpportunityList: any;
        if (queueId != -1234567) {
          sourceOpportunityList = this.opportunityQueues.find(x => x.queueId == queueId).InProgress;
          targetOpportunityList = this.opportunityQueues.find(x => x.queueId == queueId).Complete;
          currentOpportunity = sourceOpportunityList.find(x => x.OpportunityId == opportunityId);
        }
        else {
          sourceOpportunityList = this.inProgressList;
          targetOpportunityList = this.completedList;
          currentOpportunity = this.inProgressList.find(x => x.OpportunityId == opportunityId);
        }
        this.assetList = currentOpportunity.Assets;
        let allRemainingAssetsAlreadyCompleted = true;
        for (var i = 0; i < this.assetList.length; i++)
          if (this.assetList[i].AssetId != assetId && this.assetList[i].AssetName != assetName)
            if (this.assetList[i].OrderStatus != "Complete")
              allRemainingAssetsAlreadyCompleted = false;
        let currObj = this;
        if (allRemainingAssetsAlreadyCompleted)
          bootbox.confirm({
            message: "Do you want to mark this opportunity as completed?",
            callback: function (result) {
              if (result) {
                for (var i = 0; i < currObj.assetList.length; i++)
                  currObj.assetList[i]["OrderStatus"] = "Complete";
                currentOpportunity["Status"] = "Complete";
                currObj.updateOpportunityStatusAfterTransfer(currentOpportunity["OpportunityId"], currObj.inProgressList, currObj.completedList, "inprogress", "complete", currentOpportunity["Status"], "ungrouped");

                if (queueId != -1234567) {
                  currObj.opportunityQueues.find(x => x.queueId == queueId).Complete.push(currObj.opportunityQueues.find(x => x.queueId == queueId).InProgress.find(x => x.OpportunityId == currentOpportunity["OpportunityId"]));

                  currObj.opportunityQueues.find(x => x.queueId == queueId).InProgress = currObj.opportunityQueues.find(x => x.queueId == queueId).InProgress.filter(x => x.OpportunityId != currentOpportunity["OpportunityId"]);
                } else {

                }
                // if (queueId != -1234567)
                //   transferArrayItem(currObj.opportunityQueues.find(x => x.queueId == queueId).InProgress, currObj.opportunityQueues.find(x => x.queueId == queueId).Complete, event.previousIndex, event.currentIndex);
                // else
                //   transferArrayItem(currObj.opportunityQueues.find(x => x.queueId == queueId).InProgress, currObj.opportunityQueues.find(x => x.queueId == queueId).Complete, event.previousIndex, event.currentIndex);
              }
            }
          }).find(".btn-primary").removeClass("btn").css({
            padding: "6px 12px",
            "border-radius": "0rem",
            border: "1px solid transparent"
          }).find(".modal-content").css({
            "border-radius": "0rem"
          });
        else {
          this.UpdateAssetStatus(assetId, newStatus);
          this.setNewStatus(queueId, opportunityList, opportunityId, assetId, assetName, newStatus);
        }
      } else {
        this.UpdateAssetStatus(assetId, newStatus);
        this.setNewStatus(queueId, opportunityList, opportunityId, assetId, assetName, newStatus);
      }

      this.ajWebService.UpdateAssetCompletedStatus().subscribe(data => {
        console.log(data);
      },
        error => {
          console.log(error);
        })

      this.ajWebService.UpdateOpportunityAssetStatus().subscribe(data => {
        console.log(data);
      },
        error => {
          console.log(error);
        })
    }
    this.recalculateAssetList();
  }

  UpdateAssetStatus(assetId, newStatus) {
    this.ajWebService.UpdateAssetStatus(assetId, newStatus).subscribe(data => {
      console.log(data);
    },
      error => {
        console.log(error);
      })
  }

  setNewStatus(queueId: number, opportunityList: string, opportunityId: number, assetId: number, assetName: string, newStatus: string) {
    if (queueId == -1234567)
      this.opportunityQueues[queueId][opportunityList].find(x => x.OpportunityId == opportunityId).Assets.find(x => x.AssetId == assetId && x.AssetName == assetName).OrderStatus = newStatus;
    else if (opportunityList == "InProgress")
      this.inProgressList.find(x => x.OpportunityId == opportunityId).Assets.find(x => x.AssetId == assetId && x.AssetName == assetName).OrderStatus = newStatus;
    else if (opportunityList == "Complete")
      this.completedList.find(x => x.OpportunityId == opportunityId).Assets.find(x => x.AssetId == assetId && x.AssetName == assetName).OrderStatus = newStatus;
    else
      this.cancelledList.find(x => x.OpportunityId == opportunityId).Assets.find(x => x.AssetId == assetId && x.AssetName == assetName).OrderStatus = newStatus;
  }

  searchOpportunityLists(searchText: string) {
    this.populateData();
    searchText = searchText != undefined && searchText != "" ? searchText.toString().toLowerCase().trim() : "";
    if (this.grouping) {
      if (searchText != "") {
        for (var i = 0; i < this.opportunityQueues.length; i++) {
          this.opportunityQueues[i]["Backlog"] = this.copyOpportunityQueues[i]["Backlog"].filter(row => {
            return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
          })
          this.opportunityQueues[i]["InProgress"] = this.copyOpportunityQueues[i]["InProgress"].filter(row => {
            return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
          })
          this.opportunityQueues[i]["Complete"] = this.copyOpportunityQueues[i]["Complete"].filter(row => {
            return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
          })
          this.opportunityQueues[i]["Cancelled"] = this.copyOpportunityQueues[i]["Cancelled"].filter(row => {
            return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
          })
        }
        //this.opportunityQueuesFilteredData = JSON.parse(JSON.stringify(this.opportunityQueuesBackup));
      } else
        this.reCalculateTotalSavingsAndEfforts();
    } else {
      if (searchText != "") {
        this.backlogList = this.backlogListBackup.filter(row => {
          return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
        })
        this.inProgressList = this.inProgressListBackup.filter(row => {
          return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
        })
        this.completedList = this.completedListBackup.filter(row => {
          return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
        })
        this.cancelledList = this.cancelledListBackup.filter(row => {
          return ((row.OpportunityName.toLowerCase() != undefined ? row.OpportunityName.toLowerCase().includes(searchText) : false));
        })
      } else {
        this.backlogList = this.backlogListBackup;
        this.inProgressList = this.inProgressListBackup;
        this.completedList = this.completedListBackup;
        this.cancelledList = this.cancelledListBackup;
      }
    }

    // for (var i = 0; i < this.opportunityQueues.length; i++) {
    //   var id =  this.opportunityQueues[i]["queueId"];
    //   $('#expandsearch' + id).removeClass('ml-auto myw-accordion-toggle');
    //   $('#expandsearch' + id).addClass('ml-auto myw-accordion-toggle collapsed');
    // }
    //$("a").find(".ml-auto myw-accordion-toggle").addClass("collapsed");

    if (searchText == "") {
      var list = this.opportunityQueues;
      setTimeout(function () {
        for (var i = 0; i < list.length; i++) {
          var id = list[i]["queueId"];
          $('#expandsearch' + id).removeClass('ml-auto myw-accordion-toggle');
          $('#expandsearch' + id).addClass('ml-auto myw-accordion-toggle collapsed');
        }
      }, 10)
    }
  }

  clearSearch() {
    this.reCalculateTotalSavingsAndEfforts();
  }

  sortGroupedOpportunities(queueId: number, status: string) {
    this.groupedOQSortOrders.find(x => x["queueId"] == queueId)["" + status + ""] ?
      this.opportunityQueues.find(x => x["queueId"] == queueId)["" + status + ""].sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? -1 : 1) :
      this.opportunityQueues.find(x => x["queueId"] == queueId)["" + status + ""].sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? 1 : -1);

    this.groupedOQSortOrders.find(x => x["queueId"] == queueId)["" + status + ""] = !this.groupedOQSortOrders.find(x => x["queueId"] == queueId)["" + status + ""];
  }

  sortUnGroupedOpportunities(status: string) {
    if (status == "Backlog") {
      this.unGroupedBacklogSortOrder ? this.backlogList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? -1 : 1) : this.backlogList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? 1 : -1);
      this.unGroupedBacklogSortOrder = !this.unGroupedBacklogSortOrder;
    }
    else if (status == "InProgress") {
      this.unGroupedInProgressSortOrder ? this.inProgressList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? -1 : 1) : this.inProgressList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? 1 : -1);
      this.unGroupedInProgressSortOrder = !this.unGroupedInProgressSortOrder;
    }
    else if (status == "Complete") {
      this.unGroupedCompletedSortOrder ? this.completedList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? -1 : 1) : this.completedList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? 1 : -1);
      this.unGroupedCompletedSortOrder = !this.unGroupedCompletedSortOrder;
    }
    else {
      this.unGroupedCancelledSortOrder ? this.cancelledList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? -1 : 1) : this.cancelledList.sort((a, b) => a.OpportunityName.toLowerCase() > b.OpportunityName.toLowerCase() ? 1 : -1);
      this.unGroupedCancelledSortOrder = !this.unGroupedCancelledSortOrder;
    }
  }

  // GetAOFAssessments() {
  //   this.ajWebService.GetAOFAssessments(this.ajWebService.endToEndUId.toLowerCase(), this.ajWebService.CMSUId, this.ajWebService.clientWMSId).subscribe(data => {
  //     this.AOFAssessments = data;
  //     if (this.AOFAssessments != null) {
  //       this.AOFAssessments.forEach((value, key) => {
  //         if (value.AssessmentStatus != null && value.AssessmentStatus == "Submitted") {
  //           this.AOFRagStatusClass = "ragGreen";
  //         }
  //         else if (this.AOFRagStatusClass != "ragGreen" && value.AssessmentStatus != null && value.AssessmentStatus == "InProgress") {
  //           this.AOFRagStatusClass = "ragYellow";
  //         }
  //       });
  //       if (this.AOFRagStatusClass != "ragYellow" && this.AOFRagStatusClass != "ragGreen") {
  //         this.AOFRagStatusClass = "ragRed";
  //       }
  //     }
  //   },
  //     error => {
  //       console.log(error)
  //     })
  // }

  GetProductList() {
    this.ajWebService.GetProductList(this.ajWebService.endToEndUId.toLowerCase()).subscribe(data => {
      console.log(data)
    },
      error => {
        console.log(error)
      })
  }

  getAssetAttributeUsage() {
    this.assetId = this.assetsList[0]["AssetId"];
    this.ajWebService.GetAssetAttributeUsage(this.opportunityId, this.assetId).subscribe(data => {
      console.log(data)
    },
      error => {
        console.log(error)
      })
  }

  openOpportunityQueue(queueId: number) {
    this.queueId = queueId;
  }

  openAssetDataAndCharts(opportunityId: number, benefitRealizationDate: any) {
    if (benefitRealizationDate != undefined) {
      if (this.ajWebService.lastActiveOpportunityId == undefined)
        this.ajWebService.lastActiveOpportunityId = opportunityId;
      else if (this.ajWebService.lastActiveOpportunityId != opportunityId) {
        $("#toggleCompletedCollapse" + this.ajWebService.lastActiveOpportunityId).addClass('collapsed');
        $("#toggleCompletedCollapse" + this.ajWebService.lastActiveOpportunityId).attr('aria-expanded', 'false');
        this.ajWebService.lastActiveOpportunityId = opportunityId;
      }
      else
        this.ajWebService.lastActiveOpportunityId = undefined;
      this.opportunityId = opportunityId;
    }
    else
      this.ajWebService.showInfoMessage("Please enter benefit realization date.")
  }

  openTicketDistributionChart() {
    this.ajWebService.showTicketDistributionChartInPopUp.emit("true");
  }

  openUseCasesImplementedChart() {
    this.ajWebService.showUseCasesImplementationChartInPopUp.emit("true");
  }

  savePreviousValue(previousValue: number) {
    this.previousValue = previousValue;
  }

  fillBlankValuesinPopups(popup: string, moreThan12months: boolean, noOfMonths: any) {
    if (moreThan12months) {
      for (var i = 0; i < noOfMonths.length; i++) {
        if (popup == 'ticketVolume') {
          $("#totalOccurence-" + noOfMonths[i].replace(' ', '')).val(0);
          $("#automatedFully-" + noOfMonths[i].replace(' ', '')).val(0);
          $("#automatedPartially-" + noOfMonths[i].replace(' ', '')).val(0);
        } else {
          $("#value-" + noOfMonths[i].replace(' ', '')).val(0);
          $("#fte-" + noOfMonths[i].replace(' ', '')).val(0);
        }
      }
    } else {
      var monthYear: String = '';
      this.usageValues = [];
      for (var i = 1; i <= 12; i++) {
        monthYear = this.monthsList[this.currentDate.getMonth() + i] != undefined ? this.monthsList[this.currentDate.getMonth() + i] + this.yearsList[0] : this.monthsList[this.currentDate.getMonth() + i - 12] + this.yearsList[1];
        if (popup == 'ticketVolume') {
          $("#totalOccurence-" + monthYear).val(0);
          $("#automatedFully-" + monthYear).val(0);
          $("#automatedPartially-" + monthYear).val(0);

          $("#totalOccurence-" + monthYear).css('color', 'lightgrey');
          $("#automatedFully-" + monthYear).css('color', 'lightgrey');
          $("#automatedPartially-" + monthYear).css('color', 'lightgrey');

          $("#totalOccurence-" + monthYear).attr('readonly', 'readonly');
          $("#automatedFully-" + monthYear).attr('readonly', 'readonly');
          $("#automatedPartially-" + monthYear).attr('readonly', 'readonly');
        } else {
          $("#value-" + monthYear).val(0);
          $("#fte-" + monthYear).val(0);

          $("#value-" + monthYear).css('color', 'lightgrey');
          $("#fte-" + monthYear).css('color', 'lightgrey');

          $("#value-" + monthYear).attr('readonly', 'readonly');
          $("#fte-" + monthYear).attr('readonly', 'readonly');
        }
        this.usageValues.push({ Month: monthYear.substr(0, 3) + ' ' + monthYear.substr(3, 2), Value: 0 });
      }
    }
  }

  getTicketVolume() {
    this.ajWebService.getTicketVolume(this.opportunityId).subscribe(data => {
      if (data != undefined) {
        this.noOfMonthsArray = [];
        let obj = this;
        if (data["AttributeUsage"][0].UsageValues.length > 12 && data["AttributeUsage"][1].UsageValues.length > 12 && data["AttributeUsage"][2].UsageValues.length > 12) {
          this.moreThan12months = true;
          for (var i = 0; i < data["AttributeUsage"][0].UsageValues.length; i++)
            this.noOfMonthsArray.push(data["AttributeUsage"][0].UsageValues[i].Month);
          setTimeout(function () {
            obj.fillBlankValuesinPopups('ticketVolume', obj.moreThan12months, obj.noOfMonthsArray);
            for (var i = 0; i < obj.noOfMonthsArray.length; i++) {
              $("#automatedFully-" + obj.noOfMonthsArray[i].replace(' ', '')).val(data["AttributeUsage"][0].UsageValues[i].Value);
              $("#automatedPartially-" + obj.noOfMonthsArray[i].replace(' ', '')).val(data["AttributeUsage"][1].UsageValues[i].Value);
              $("#totalOccurence-" + obj.noOfMonthsArray[i].replace(' ', '')).val(data["AttributeUsage"][2].UsageValues[i].Value);
            }
          }, 500)
        } else {
          this.moreThan12months = false;
          this.noOfMonthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          setTimeout(function () {
            obj.fillBlankValuesinPopups('ticketVolume', obj.moreThan12months, []);
            let ticketVolumeDate: Date;
            let inputField: any;
            let bdate = new Date(obj.benefitRealizationDate)
            obj.usageValues = data["AttributeUsage"].find(x => x.AttributeName == "Total Occurrences")["UsageValues"];
            for (var i = 0; i < obj.usageValues.length; i++) {
              inputField = $("#totalOccurence-" + obj.usageValues[i]["Month"].replace(' ', ''));
              if (inputField[0] != undefined) {
                ticketVolumeDate = new Date(obj.usageValues[i]["Month"].substr(0, 3) + " 20" + obj.usageValues[i]["Month"].substr(4, 2));
                if (ticketVolumeDate >= bdate || (ticketVolumeDate.getFullYear() == bdate.getFullYear() && bdate.getMonth() == ticketVolumeDate.getMonth())) {
                  inputField.removeAttr('readonly');
                  inputField.css('color', '');
                  inputField.val(obj.usageValues[i]["Value"]);
                }
              }
            }

            obj.usageValues = data["AttributeUsage"].find(x => x.AttributeName == "Automated Fully")["UsageValues"];
            for (var i = 0; i < obj.usageValues.length; i++) {
              inputField = $("#automatedFully-" + obj.usageValues[i]["Month"].replace(' ', ''));
              if (inputField[0] != undefined) {
                ticketVolumeDate = new Date(obj.usageValues[i]["Month"].substr(0, 3) + " 20" + obj.usageValues[i]["Month"].substr(4, 2));
                if (ticketVolumeDate >= bdate || (ticketVolumeDate.getFullYear() == bdate.getFullYear() && bdate.getMonth() == ticketVolumeDate.getMonth())) {
                  inputField.removeAttr('readonly');
                  inputField.css('color', '');
                  inputField.val(obj.usageValues[i]["Value"]);
                }
              }
            }

            obj.usageValues = data["AttributeUsage"].find(x => x.AttributeName == "Automated Partially")["UsageValues"];
            for (var i = 0; i < obj.usageValues.length; i++) {
              inputField = $("#automatedPartially-" + obj.usageValues[i]["Month"].replace(' ', ''));
              if (inputField[0] != undefined) {
                ticketVolumeDate = new Date(obj.usageValues[i]["Month"].substr(0, 3) + " 20" + obj.usageValues[i]["Month"].substr(4, 2));
                if (ticketVolumeDate >= bdate || (ticketVolumeDate.getFullYear() == bdate.getFullYear() && bdate.getMonth() == ticketVolumeDate.getMonth())) {
                  inputField.removeAttr('readonly');
                  inputField.css('color', '');
                  inputField.val(obj.usageValues[i]["Value"]);
                }
              }
            }
          }, 500)
        }
      }
    },
      error => {
        this.ajWebService.showInfoMessage(error.toString());
      })
  }

  saveTicketVolume(id: string, value: number, attributeId: number) {
    $("#" + id).val() == undefined || $("#" + id).val() == '' || $("#" + id).val().toString().trim() == '' ? $("#" + id).val(0) : '';
    if ($("#totalOccurence-" + id.substr(id.indexOf('-') + 1, 5)).val() >= +$("#automatedFully-" + id.substr(id.indexOf('-') + 1, 5)).val() + +$("#automatedPartially-" + id.substr(id.indexOf('-') + 1, 5)).val()) {
      $("#infoMessage").html('');
      var finalData: Object = {};
      finalData["IsAssetLevelUsageEnabled"] = false;
      finalData["attributeUsages"] = [];
      finalData["attributeUsages"].push({
        'AttributeId': attributeId,
        'AttributeUsage': +value,
        'ModifiedOn': new Date("15 " + id.substr(id.indexOf('-') + 1, 3) + ' 20' + id.substr(id.indexOf('-') + 4, 6)),
        'OpportunityId': this.opportunityId
      })

      this.ajWebService.saveAttributeUsageForTicketVolume(finalData).subscribe(data => {

      },
        error => {
          this.spinner.hide();
          this.ajWebService.showInfoMessage(error.toString());
        })
    } else {
      $("#infoMessage").html('Sum of Automated Fully and Automated Partially should be less than Total occurrences');
      $("#" + id).val(this.previousValue);
    }
  }

  updateTicketVolumeChart() {
    this.ajWebService.ticketVolumeDataUpdated.emit("TicketVolDonutChart-" + this.queueId + "-" + this.opportunityId.toString());
  }

  getSavings() {
    this.ajWebService.GetSavingsValue(this.opportunityId).subscribe(data => {
      if (data != undefined) {
        this.noOfMonthsArray = [];
        let obj = this;
        if (data["AttributeValue"][0].Values.length > 12 && data["AttributeValue"][1].Values.length > 12) {
          this.moreThan12months = true;
          for (var i = 0; i < data["AttributeValue"][0].Values.length; i++)
            this.noOfMonthsArray.push(data["AttributeValue"][0].Values[i].Month);
          setTimeout(function () {
            obj.fillBlankValuesinPopups('savingsAndFTE', obj.moreThan12months, obj.noOfMonthsArray);
            for (var i = 0; i < obj.noOfMonthsArray.length; i++) {
              $("#value-" + obj.noOfMonthsArray[i].replace(' ', '')).val(data["AttributeValue"][0].Values[i].Value);
              $("#fte-" + obj.noOfMonthsArray[i].replace(' ', '')).val(data["AttributeValue"][1].Values[i].Value);
            }
          }, 500)
        } else {
          this.moreThan12months = false;
          this.noOfMonthsArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
          setTimeout(function () {
            obj.fillBlankValuesinPopups('savingsAndFTE', obj.moreThan12months, []);
            let savingsFTEDate: Date;
            let inputField: any;
            let bdate = new Date(obj.benefitRealizationDate)
            obj.usageValues = data["AttributeValue"].find(x => x.AttributeName == "Values ($k)")["Values"];
            for (var i = 0; i < obj.usageValues.length; i++) {
              inputField = $("#value-" + obj.usageValues[i]["Month"].replace(' ', ''));
              if (inputField[0] != undefined) {
                savingsFTEDate = new Date(obj.usageValues[i]["Month"].substr(0, 3) + " 20" + obj.usageValues[i]["Month"].substr(4, 2));
                if (savingsFTEDate >= bdate || (savingsFTEDate.getFullYear() == bdate.getFullYear() && bdate.getMonth() == savingsFTEDate.getMonth())) {
                  inputField.removeAttr('readonly');
                  inputField.css('color', '');
                  inputField.val(obj.usageValues[i]["Value"]);
                }
              }
            }

            obj.usageValues = data["AttributeValue"].find(x => x.AttributeName == "FTE")["Values"];
            for (var i = 0; i < obj.usageValues.length; i++) {
              inputField = $("#fte-" + obj.usageValues[i]["Month"].replace(' ', ''));
              if (inputField[0] != undefined) {
                savingsFTEDate = new Date(obj.usageValues[i]["Month"].substr(0, 3) + " 20" + obj.usageValues[i]["Month"].substr(4, 2));
                if (savingsFTEDate >= bdate || (savingsFTEDate.getFullYear() == bdate.getFullYear() && bdate.getMonth() == savingsFTEDate.getMonth())) {
                  inputField.removeAttr('readonly');
                  inputField.css('color', '');
                  inputField.val(obj.usageValues[i]["Value"]);
                }
              }
            }
          }, 500)
        }
      }
    },
      error => {
        console.log(error)
      })
  }

  saveSavings(valueOrFTE: string, id: string, value: number, attributeId: number) {
    $("#" + id).val() == undefined || $("#" + id).val() == '' || $("#" + id).val().toString().trim() == '' ? $("#" + id).val(0) : '';

    var finalData = [];
    finalData.push({
      'AttributeId': attributeId,
      'ValueCost': +value,
      'ModifiedOn': new Date("15 " + id.substr(id.indexOf('-') + 1, 3) + ' 20' + id.substr(id.indexOf('-') + 4, 6)),
      'OpportunityId': this.opportunityId
    })

    this.ajWebService.saveValuesAndFTEforSavings(finalData).subscribe(data => {
      this.totalSavingsValueForNullAttribute = 0;
      if (this.opportunityQueues.find(x => x.queueId == this.queueId).Complete.find(x => x.OpportunityId == this.opportunityId).AttributeValue != undefined)
        if (this.opportunityQueues.find(x => x.queueId == this.queueId).Complete.find(x => x.OpportunityId == this.opportunityId).AttributeValue.AttributeValue.find(x => x.AttributeName == valueOrFTE).Values.find(x => x.Month.replace(' ', '') == id.substr(id.indexOf('-') + 1, 5)) != undefined)
          this.opportunityQueues.find(x => x.queueId == this.queueId).Complete.find(x => x.OpportunityId == this.opportunityId).AttributeValue.AttributeValue.find(x => x.AttributeName == valueOrFTE).Values.find(x => x.Month.replace(' ', '') == id.substr(id.indexOf('-') + 1, 5)).Value = +value;
        else
          this.opportunityQueues.find(x => x.queueId == this.queueId).Complete.find(x => x.OpportunityId == this.opportunityId).AttributeValue.AttributeValue.find(x => x.AttributeName == valueOrFTE).Values.push({
            'Month': id.substr(id.indexOf('-') + 1, 10).substr(0, 3) + " " + id.substr(id.indexOf('-') + 1, 10).substr(3, 2),
            'Value': +value
          })
      else
        for (var i = 0; i < document.getElementsByClassName('savingsvalue').length; i++)
          this.totalSavingsValueForNullAttribute += +$("#" + document.getElementsByClassName('savingsvalue')[i].getAttribute('id')).val();

      this.reCalculateTotalSavingsAndEfforts();
    },
      error => {
        this.ajWebService.showInfoMessage(error.toString());
      })
  }

  updateSavingsFTEChart() {
    this.ajWebService.savingsAndFTEDataUpdated.emit(this.opportunityId.toString());
    this.reCalculateTotalSavingsAndEfforts();
  }

  UpdatePlannedDate(OpportunityId: number, PlannedDate: string) {
    var val = (PlannedDate["value"].getMonth() + 1) + "-" + PlannedDate["value"].getDate() + "-" + PlannedDate["value"].getFullYear();
    this.ajWebService.UpdateDueDate(OpportunityId, val).subscribe(data => {
      this.reCalculateTotalSavingsAndEfforts();
    },
      error => {
        console.log(error);
      })
  }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner(heatMapDataReceived: boolean, supportGroupDataReceived: boolean) {
    if (supportGroupDataReceived)
      this.spinner.hide();
  }

  ExcelReport() {
    this.totalAsset = this.inProgressassetList.length + this.completedassetList.length;
    this.clientName = [this.ajWebService.clientList];
    //this.clientName = [this.clientList[0].Name];
    this.ajWebService.GetOpportunityList(this.ajWebService.LoadOpportunityRequest).subscribe(response => {
      this.ExcelDataAutomationPlanner = [];
      this.ExcelDataAutomationPlanner = response;
      //console.log(response);
      this.ajWebService.GetAssetCount(this.totalAsset).subscribe(response => {
        this.ajWebService.GetContract(this.clientName).subscribe(response => {
          this.ajWebService.ExcelReportDownload(this.ExcelDataAutomationPlanner).subscribe(response => {
            //console.log(response)
            var blob = new Blob([response], {
              type: "application/vnd.ms-excel"
            });
            var currentDate = new Date();
            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1;
            var year = currentDate.getFullYear();
            FileSaver.saveAs(blob, 'AJ_Report_Download_' + day + "_" + month + "_" + year + '.xlsx');
            this.ajWebService.showSuccessMessage("Data Exported to Excel")
          }, error => {
            this.ajWebService.showErrorMessage("Export Failed")
          });
        }, error => {
          this.ajWebService.showErrorMessage(error)
        });
      }, error => {
        this.ajWebService.showErrorMessage(error)
      })
    });
  }

}
