import { Component, OnInit } from '@angular/core';
import { WindowUtils } from 'msal';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(window.location.hostname.includes("ciostage")){
      if(window.location.hostname == "mywizardajnextgen.ciostage.accenture.com"){
        window.location.href="https://mywizardajgov.ciostage.accenture.com/gov";
      }
      else if (window.location.hostname == "mywizardajeunextgen.ciostage.accenture.com"){
        window.location.href="https://mywizardajeugov.ciostage.accenture.com/gov";
      } 
    }
    else if(window.location.hostname.includes("demo")){
      window.location.href="https://mywizardajgovdemo.accenture.com/gov";
    }
    else{
      if(window.location.hostname == "mywizardaj.accenture.com"){
        window.location.href="https://mywizardajgov.accenture.com/gov";
      }
      else if (window.location.hostname == "mywizardajeu.accenture.com"){
        window.location.href="https://mywizardajeugov.accenture.com/gov";
      } 
    }
  }

}
