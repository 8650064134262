import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MsalModule, MsalInterceptor, MsalService } from '@azure/msal-angular';
import {
  MSAL_GUARD_CONFIG,
  // MSAL_CONFIG_ANGULAR,
  MsalGuardConfiguration
  , MsalService, MsalModule, MsalInterceptor, MSAL_INSTANCE, MsalGuard, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService
} from '@azure/msal-angular';
import { Configuration } from 'msal';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppComponent } from './app.component';
import { AJWebService } from '../services/ajWeb.service';

import { HomeComponent } from '../components/home/home.component';
import { DonutChartComponent } from '../DoNutGraph/donut-chart.component';
import { DonutChartTicketComponent } from '../DoNutTicketGraph/donut-chart.component';
import { BulletChartComponent } from '../BulletGraph/bullet-chart.component';
import { MultilineTopTrendingAssetsComponent } from '../Multiline-top-trending-assets/multiline-top-trending-assets.component';
import { StandardLineChartComponent } from '../4.0-standard-line-chart/standard-line-chart.component';
import { outcomes_TotSaving_BulletChartComponent } from '../outcomes_bulletChart/outcomes_TotSaving_bullet-chart.component';
import { UseCasesImplementationComponent } from '../useCasesImplementationChart/use-cases-implementation.component';
import { outcomes_TotEffort_BulletChartComponent } from '../outcomes_bulletChart/outcomes_TotEffort_bullet-chart.component';
import { ClusterBarChartTwoComponent } from '../use-Case-Imp-cluster-bar-chart-popup/useCasePopUp-cluster-bar-chart-two.component';
import { SavingHrsBulletChartComponent } from '../savinghrs-bullet-graph/savinghrs-bullet-chart.component';
import { MultilineTickVolTrendingAssetsComponent } from '../ticket-vol-multiline-graph/multiline-tickvol-trending-assets.component'
import { FTELineChartComponent } from '../fte-line-chart/fte-line-chart.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { SearchOLPipeComponent } from '../searchFilterPipe';
import { HeatMapChartComponent } from '../heat-map-chart/heat-map-chart.component';
import { HeatMapPopUpChartComponent } from '../heat-map-chart-popUp/heat-map-popup-chart.component';
import { CacheInterceptor } from '../shared/cache-interceptor';
import { TooltipModule } from 'node_modules/ng2-tooltip-directive';
import { SavingHrsBulletChartComponentCompSec } from '../BulletGraph_savinghrs-UnderCompletedSection/savinghrs-bullet-chart.CompSec.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClickOutsideModule } from 'ng-click-outside';
import { GovernanceComponent } from './governance/governance.component';
import { MsalApplicationModule } from './msal-application.module';
import { CommentBoxComponent } from '../comment-box/comment-box.component';
import { AssetProvisioningStatusComponent } from '../asset-provisioning-status/asset-provisioning-status.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DonutChartComponent,
    DonutChartTicketComponent,
    outcomes_TotSaving_BulletChartComponent,
    MultilineTopTrendingAssetsComponent,
    StandardLineChartComponent,
    BulletChartComponent,
    outcomes_TotEffort_BulletChartComponent,
    UseCasesImplementationComponent,
    ClusterBarChartTwoComponent,
    MultilineTopTrendingAssetsComponent,
    StandardLineChartComponent,
    SavingHrsBulletChartComponent,
    MultilineTickVolTrendingAssetsComponent,
    FTELineChartComponent,
    SearchOLPipeComponent,
    HeatMapChartComponent,
    HeatMapPopUpChartComponent,
    SavingHrsBulletChartComponentCompSec,
    GovernanceComponent,
    CommentBoxComponent,
    AssetProvisioningStatusComponent
  ],
  imports: [
    MsalApplicationModule.forRoot('config/config.json'),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    DragDropModule,
    DatePickerModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    TooltipModule,
    ClickOutsideModule
  ],
  providers: [
    BnNgIdleService,
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    MsalService,
    AJWebService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
