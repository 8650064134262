<!-- <div class="top-trending-assets-chart">
    <div class="heading-section">
        <span id="heading">Top Trending Assets</span>
    </div>
    <div class="top-trending-assets-chart__wrapper">
    <div id="top-trending-assets" class="top-trending-assets"></div>
    <div>
        <app-graph-carousel (nextPageEmit)="nextPage($event)" (prevPageEmit)="prevPage($event)"
            [countPerPage]="barnumber" [graphData]="data" [curPage]="page">
        </app-graph-carousel>
    </div>
</div>
</div> -->

<div>
    <div id="top-trending-assets" class="top-trending-assets"></div>
</div>